import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Score } from './Score';
import { ScoreContainer } from './ScoreContainer';

export const ScoreSwitch: React.FC<RouteComponentProps<{
  id?: string;
}>> = ({ match: { url, path } }) => (
  <React.Fragment>
    <BreadcrumbsItem to={url}>Digital Match Report</BreadcrumbsItem>
    <Switch>
      <Route exact path={path}>
        <ScoreContainer />
      </Route>

      <Route path={`${path}/:id([0-9]+)/match`}>
        <Score />
      </Route>
      <Route>
        <Redirect to={path} />
      </Route>
    </Switch>
  </React.Fragment>
);
