import { DialogProps, Grid, TextField, FormControlLabel, Switch} from '@material-ui/core';
import { ApiClient } from 'ApiClient';
import { Autocomplete } from '@material-ui/lab';
import { FormDialog, SnackbarContext } from 'components';
import { useApiClient, usePrevious } from 'hooks';
import { Club, ClubOfficial, Team, ClubOfficialType } from 'models';
import * as React from 'react';
import { ClubOfficialsOptions } from 'enums';

interface Props {
  dialogProps: DialogProps;
  refetch: () => void;
  club: Club;
  clubOfficial?: ClubOfficial;
}

interface ClubOfficialForm {
  name?: string;
  surname?: string;
  mobile?: string;
  address?: string;
  email?: string;
  saveAsUser?: number;
  isPlayer?: number;
  previousSaveAsUser : number;
  teams?: Team[];
  officialTypes?: ClubOfficialType[];
}

export const SaveClubOfficialDialog: React.FC<Props> = ({
  dialogProps,
  club,
  clubOfficial,
  refetch,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const [{ data }] = useApiClient<ClubOfficialType[]>('club-officials-types');
  const clubOfficialTypes = data ?? [];
  
  const getForm = React.useCallback(function getForm(
    clubOfficial?: ClubOfficial,
  ): ClubOfficialForm {
    return {
      name: clubOfficial?.name ?? undefined,
      surname: clubOfficial?.surname ?? undefined,
      mobile: clubOfficial?.mobile ?? undefined,
      address: clubOfficial?.address ?? undefined,
      saveAsUser: clubOfficial?.saveAsUser ?? 0,
      isPlayer: clubOfficial?.isPlayer ?? 0,
      previousSaveAsUser: clubOfficial?.saveAsUser ?? 0,
      email: clubOfficial?.email ?? undefined,
      teams: clubOfficial?.clubOfficialTeams?.map((t) => t.team) ?? undefined,
      officialTypes: clubOfficial?.officialsClubTypes.map((t) => t.clubOfficialsType) ?? [],
    };
  },
  []);
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(getForm(clubOfficial));
  
  console.log(form.teams);
  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(clubOfficial));
    }
  }, [getForm, clubOfficial, justOpened]);

  return (
    <FormDialog
      title={`${clubOfficial ? 'Edit' : 'Add'} Club Official`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="Name"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Surname"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="surname"
            value={form.surname || ''}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Address"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="address"
            value={form.address || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Mobile"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="mobile"
            value={form.mobile || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Email"
            type="text"
            disabled={clubOfficial ? true : false}
            fullWidth
            onChange={(e) => handleChange(e)}
            name="email"
            value={form.email || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            value={clubOfficialTypes.filter((t) => form.officialTypes?.map((ot) => ot.id).includes(t.id) ?? [])}
            multiple
            options={clubOfficialTypes}
            getOptionLabel={(option: ClubOfficialType) => option?.name ?? ''}
            onChange={(e, officialTypes) =>
              handleChangeOfficialType(officialTypes)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="officialTypes"
                variant="standard"
                label="Club Official Types"
                inputProps={{
                  ...params.inputProps,
                  required: form.officialTypes?.length === 0
                }}
                required={true}
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            multiple
            value={form && form.teams && form.teams.length > 0 ? club.teams.filter((t) => form.teams?.map((ot) => ot.id).includes(t.id)) : []}
            options={club.teams}
            getOptionLabel={(option: Team) => option?.name + '-' + option?.teamCategory?.name ?? ''}
            onChange={(e, teams) =>
              handleChangeClubTeam(teams)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="teams"
                variant="standard"
                label="Team Coach"
                inputProps={{
                  ...params.inputProps,
                  required: (!form.teams && (form.officialTypes?.map((x) => x.id).includes(ClubOfficialsOptions.ASSISTANT_COACH) || form.officialTypes?.map((x) => x.id).includes(ClubOfficialsOptions.COACH)))
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={form.isPlayer !== undefined && form.isPlayer === 1 ? true : false}
                  onChange={(e, checked) => handleIsPlayerChange(checked)}
                  name="isPlayer"
                  color="primary"
                />
              }
              label="Is Player?"
            />
            <FormControlLabel
              control={
                <Switch
                  disabled={form.previousSaveAsUser === 1}
                  checked={form.saveAsUser !== undefined && form.saveAsUser === 1 ? true : false}
                  onChange={(e, checked) => handleSaveUserChange(checked)}
                  name="saveAsUser"
                  color="primary"
                />
              }
              label="Save as user"
            />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleIsPlayerChange(checked: Boolean) {
    var value = checked === true ? 1 : 0
    setForm((f) => ({ ...f, isPlayer: value }));
  }

  function handleSaveUserChange(checked: Boolean) {
    var value = checked === true ? 1 : 0
    setForm((f) => ({ ...f, saveAsUser: value }));
  }

  function handleChangeOfficialType(officialTypes: ClubOfficialType[]) {
    const newForm = { ...form };
    newForm.officialTypes = officialTypes;
    setForm(newForm);
  }

  function handleChangeClubTeam(teams: Team[]) {
    const newForm = { ...form };
    newForm.teams = teams;
    setForm(newForm);
  }

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);
      console.log('here');
      if (!form.officialTypes?.map((x) => x.id).includes(ClubOfficialsOptions.ASSISTANT_COACH) && !form.officialTypes?.map((x) => x.id).includes(ClubOfficialsOptions.COACH)) {
        form.teams = undefined;
      }

      const data = { ...form, clubId: club.id };

      const response = await (clubOfficial
        ? ApiClient.put(`club-officials/${clubOfficial.id}`, data)
        : ApiClient.post(`club-officials`, data));

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refetch();

      snackbar.open('Club Official has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
