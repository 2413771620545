import { DialogProps, Grid, TextField } from '@material-ui/core';
import { ApiClient } from 'ApiClient';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { Venue } from 'models';
import * as React from 'react';

interface Props {
  dialogProps: DialogProps;
  refetch: () => void;
  venue?: Venue;
}

interface VenueForm {
  name?: string;
  address?: string;
}

export const SaveVenueDialog: React.FC<Props> = ({
  dialogProps,
  venue,
  refetch,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const getForm = React.useCallback(function getForm(
    venue?: Venue,
  ): VenueForm {
    return {
      name: venue?.name ?? undefined,
      address: venue?.address ?? undefined,
    };
  },
  []);

  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(getForm(venue));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(venue));
    }
  }, [getForm, venue, justOpened]);

  return (
    <FormDialog
      title={`${venue ? 'Edit' : 'Add'} Venue`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Name"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="address"
            value={form.address || ''}
            required
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const data = { ...form};

      const response = await (venue
        ? ApiClient.put(`venues/${venue.id}`, data)
        : ApiClient.post(`venues`, data));

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refetch();

      snackbar.open('Venue has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
