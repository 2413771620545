import { 
  DialogProps,
  Grid,
  makeStyles,
  IconButton,
  Toolbar,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ApiClient } from 'ApiClient';
import { FormDialog, SnackbarContext, DateTimePicker, DatePicker, ResponsiveIconButton, EmptyView, FormField,} from 'components';
import { useApiClient, usePrevious } from 'hooks';
import { Player, Nationality, PlayerRegistrationType, Team, Club } from 'models';
import * as React from 'react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format } from 'date-fns';
import { DateTimeFormat, DateFormat } from 'config';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/RemoveCircle';

interface Props {
  dialogProps: DialogProps;
  refetch: () => void;
  player?: Player;
}

interface PlayerGender {
  key: number;
  label: string;
}

interface YesNoOptions {
  key: number;
  label: string;
}

const yesNoOptions: YesNoOptions[] = [
  { key: 1, label: 'Yes' },
  { key: 0, label: 'No' },
];

const genderOptions: PlayerGender[] = [
  { key: 0, label: 'Male' },
  { key: 1, label: 'Female' },
];

interface PlayerForm {
  playerRegistrationTypeId?: number;
  mhaId?: string;
  mhaRegistrationDate?: Date;
  gender?: number;
  name?: string;
  surname?: string;
  idCard?: string;
  address?: string;
  mobile?: string;
  email?: string;
  dob?: string;
  clubId?: number;
  nationalityId?: number;
  internationalTransfer?: number;
  passportNumber?: string;
  guardianName?: string;
  guardianId?: string;
  parentConsent?: number;
  isActive?: number;
  height?: number;
  weight?: number;
  medicalCondition?: string;
  teamPlayers?: {
    id?:number;
    teamId?: number;
    shirtNumber?: number;
  }[];
}

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
    minHeight: 0,
    paddingRight: 0,
    paddingLeft: 0,
  },
  removeIcon: {
    color: theme.palette.danger.main,
  },
  table: {
    marginBottom: theme.spacing(5),
  },
  narrowPadding: {
    padding: theme.spacing(0, 1),
  },
}));

export const SavePlayerDialog: React.FC<Props> = ({
  dialogProps,
  player,
  refetch,
}) => {
  const snackbar = React.useContext(SnackbarContext);
  const classes = useStyles();
  const [{ data }] = useApiClient<Nationality[]>('nationalities');
  const nationalities = data ?? [];

  const[{ data: teams }] = useApiClient<Team[]>('teams');
  const[{ data: clubs }] = useApiClient<Club[]>('clubs');

  const [{ data: playerTypes }] = useApiClient<PlayerRegistrationType[]>('player-registration-types');
  const playerRegistrationTypes = playerTypes ?? [];

  const getForm = React.useCallback(function getForm(
    player?: Player,
  ): PlayerForm {
    return {
      clubId: player?.clubId ?? undefined,
      playerRegistrationTypeId: player?.playerRegistrationTypeId ?? undefined,
      mhaId: player?.mhaId ?? undefined,
      mhaRegistrationDate: player?.mhaRegistrationDate ?? undefined,
      gender: player?.gender === undefined ? undefined : (player.gender ? 1 : 0),
      name: player?.name ?? undefined,
      mobile: player?.mobile ?? undefined,
      surname: player?.surname ?? undefined,
      idCard: player?.idCard ?? undefined,
      address: player?.address ?? undefined,
      email: player?.email ?? undefined,
      dob: player?.dob ?? undefined,
      nationalityId: player?.nationalityId ?? undefined,
      internationalTransfer: player?.internationalTransfer === undefined ? undefined : (player.internationalTransfer ? 1 : 0),
      passportNumber: player?.passportNumber ?? undefined,
      guardianName: player?.guardianName ?? undefined,
      guardianId: player?.guardianId ?? undefined,
      parentConsent: player?.parentConsent === undefined ? undefined : (player.parentConsent ? 1 : 0),
      isActive: player?.isActive === undefined ? undefined : (player.isActive ? 1 : 0),
      height: player?.height ?? undefined,
      weight: player?.weight ?? undefined,
      medicalCondition: player?.medicalCondition ?? undefined,
      teamPlayers: player?.teamPlayers?.map((t) => ({
        id: t.id,
        teamId: t.teamId,
        shirtNumber: t.shirtNumber
      }))
    };
  },
  []);

  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(getForm(player));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(player));
    }
  }, [getForm, player, justOpened]);

  return (
    <FormDialog
      title={`${player ? 'Edit' : 'Add'} Player`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <Autocomplete
            value={playerRegistrationTypes.find((t) => t.id === form.playerRegistrationTypeId) || null}
            options={playerRegistrationTypes}
            getOptionLabel={(option: PlayerRegistrationType) =>  option.name ?? ''}
            onChange={(e: React.ChangeEvent<{}>, type: PlayerRegistrationType | null) =>
              handleAutocompleteChange(type?.id ?? null, 'playerRegistrationTypeId')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="playerRegistrationTypeId"
                variant="standard"
                label="Player Registration Type"
                required
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="MHA Id"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="mhaId"
            value={form.mhaId || ''}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DateTimePicker
            name="mhaRegistrationDate"
            label="MHA Registration Date"
            value={form.mhaRegistrationDate || ''}
            onChange={(e: MaterialUiPickersDate) =>
              handleDateChange(e, 'mhaRegistrationDate')
            }
            fullWidth
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            value={
              genderOptions.find((s) => s.key === form.gender) || null
            }
            options={genderOptions}
            getOptionLabel={(option: PlayerGender) => option?.label ?? ''}
            onChange={(
              e: React.ChangeEvent<{}>,
              playerGender: PlayerGender | null,
            ) =>
              handleAutocompleteChange(playerGender?.key ?? null, 'gender')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="gender"
                variant="standard"
                label="Gender"
                required
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Name"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Surname"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="surname"
            value={form.surname || ''}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="I.D. Card"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="idCard"
            value={form.idCard || ''}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Address"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="address"
            value={form.address || ''}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Mobile No"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="mobile"
            value={form.mobile || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Email"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="email"
            value={form.email || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <DatePicker
            name="dob"
            label="Date of birth"
            value={form.dob || ''}
            onChange={(e: MaterialUiPickersDate) =>
              handleDateOnlyChange(e, 'dob')
            }
            fullWidth
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            value={nationalities.find((t) => t.id === form.nationalityId) || null}
            options={nationalities}
            getOptionLabel={(option: Nationality) => option?.country + ' - ' + option.nationality ?? ''}
            onChange={(e: React.ChangeEvent<{}>, nationality: Nationality | null) =>
              handleAutocompleteChange(nationality?.id ?? null, 'nationalityId')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="nationalityId"
                variant="standard"
                label="Nationality"
                required
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            value={
              yesNoOptions.find((s) => s.key === form.internationalTransfer) || null
            }
            options={yesNoOptions}
            getOptionLabel={(option: YesNoOptions) => option?.label ?? ''}
            onChange={(
              e: React.ChangeEvent<{}>,
              yesNoOption: YesNoOptions | null,
            ) =>
              handleAutocompleteChange(yesNoOption?.key ?? null, 'internationalTransfer')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="internationalTransfer"
                variant="standard"
                label="International Transfer"
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Passport No"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="passportNumber"
            value={form.passportNumber || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Guardian Name"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="guardianName"
            value={form.guardianName || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Guardian Id"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="guardianId"
            value={form.guardianId || ''}
          />
        </Grid>
        
        <Grid item md={6} xs={12}>
          <Autocomplete
            value={
              yesNoOptions.find((s) => s.key === form.parentConsent) || null
            }
            options={yesNoOptions}
            getOptionLabel={(option: YesNoOptions) => option?.label ?? ''}
            onChange={(
              e: React.ChangeEvent<{}>,
              yesNoOption: YesNoOptions | null,
            ) =>
              handleAutocompleteChange(yesNoOption?.key ?? null, 'parentConsent')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="parentConsent"
                variant="standard"
                label="Parent Consent"
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            type="number"
            label="Height (m)"
            value={form.height || ''}
            name="height"
            onChange={(e) => handleChange(e)}
            margin="none"
            fullWidth
            InputProps={{
              inputProps: { step: 0.01, min: 0 },
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            type="number"
            label="Weight (kg)"
            value={form.weight || ''}
            name="weight"
            onChange={(e) => handleChange(e)}
            margin="none"
            fullWidth
            InputProps={{
              inputProps: { step: 0.01, min: 0 },
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            value={
              yesNoOptions.find((s) => s.key === form.isActive) || null
            }
            options={yesNoOptions}
            getOptionLabel={(option: YesNoOptions) => option?.label ?? ''}
            onChange={(
              e: React.ChangeEvent<{}>,
              yesNoOption: YesNoOptions | null,
            ) =>
              handleAutocompleteChange(yesNoOption?.key ?? null, 'isActive')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="isActive"
                variant="standard"
                label="Active"
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Medical Condition"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="medicalCondition"
            value={form.medicalCondition || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            disabled={player && player.clubId ? true : false}
            value={clubs?.find((t) => t.id === form.clubId) || null}
            options={clubs ?? []}
            getOptionLabel={(option: Club) => option?.name ?? ''}
            onChange={(e: React.ChangeEvent<{}>, club: Club | null) =>
              handleAutocompleteChange(club?.id ?? null, 'clubId')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="clubId"
                variant="standard"
                label="Club"
                disabled={player && player.clubId ? true : false}
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6">Player Teams</Typography>

            <ResponsiveIconButton
              onClick={onAddPlayerTeam}
              color="primary"
              icon={AddIcon}
            >
              Add Team To Player
            </ResponsiveIconButton>
          </Toolbar>

          {!form.teamPlayers || form.teamPlayers.length === 0 ? (
            <EmptyView>No Player Teams</EmptyView>
          ) : (
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.narrowPadding}>
                  Team
                  </TableCell>
                  <TableCell className={classes.narrowPadding}>
                  Shirt Number
                  </TableCell>

                  <TableCell padding="none" />
                </TableRow>
              </TableHead>
              <TableBody>
                {form.teamPlayers.map((v, i) => (
                  <TableRow key={i}>
                    <TableCell style={{width: 300}} className={classes.narrowPadding}>
                    <Autocomplete style={{ marginTop: 9 }}
                      value={teams?.find((s) => s.id === v.teamId) || null}
                      multiple={false}
                      options={
                        teams?.filter(
                          (t) =>
                           t.clubId === form.clubId && !form.teamPlayers
                              ?.filter((c) => Boolean(c.teamId))
                              .map((c) => c.teamId)
                              .includes(t.id),
                        ) ?? []
                      }
                      getOptionLabel={(option: Team) => option?.name + "- " + option?.teamCategory?.name  ?? ''}
                      onChange={(e: React.ChangeEvent<{}>, team: Team | null) =>
                        onChangePlayerTeams(team?.id, 'teamId', i)
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="team"
                          variant="standard"
                          label=""
                          inputProps={{
                            ...params.inputProps,
                          }}
                          required={true}
                        />
                      )}
                    />
                    </TableCell>

                    <TableCell className={classes.narrowPadding}>
                      <FormField
                        fullWidth={false}
                        InputProps={{ inputProps: { min: "0" } }}
                        type="number"
                        value={v.shirtNumber ?? ''}
                        onChange={(e) => onChangePlayerTeams(e.currentTarget.value, 'shirtNumber', i)}
                      />
                    </TableCell>

                    <TableCell padding="none">
                      <IconButton
                        className={classes.removeIcon}
                        onClick={(e) => onRemovePlayerTeam(e, i)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function handleAutocompleteChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function handleDateOnlyChange(newVal: MaterialUiPickersDate, name: string) {
    setForm((f) => ({
      ...f,
      [name]: newVal && format(newVal, DateFormat.INPUT),
    }));
  }

  function handleDateChange(newVal: MaterialUiPickersDate, name: string) {
    setForm((f) => ({
      ...f,
      [name]: newVal && format(newVal, DateTimeFormat.INPUT),
    }));
  }

  function onAddPlayerTeam(e: React.MouseEvent<HTMLElement>) {

    if (form.clubId === undefined) {
      snackbar.error('Choose a club first');
      return;
    }
    const teamPlayers = form.teamPlayers?.slice() ?? [];

    teamPlayers.push({
      teamId: 0,
    });

    setForm((f) => ({
      ...f,
      teamPlayers,
    }));
  }

  function onChangePlayerTeams(
    value: any,
    name: 'teamId' | 'shirtNumber',
    i: number,
  ) {
    if (!form.teamPlayers) {
      return null;
    }

    const teamPlayers = form.teamPlayers.slice();
    const data = teamPlayers[i];

    switch (name) {
      case 'teamId':
      case 'shirtNumber':
        data[name] = parseInt(value);
        break;

      default:
        return;
    }

    setForm((f) => ({
      ...f,
      teamPlayers,
    }));
  }

  function onRemovePlayerTeam(e: React.MouseEvent<HTMLElement>, i: number) {
    if (!form.teamPlayers) {
      return null;
    }

    const teamPlayers = form.teamPlayers.slice();

    teamPlayers.splice(i, 1);

    setForm((f) => ({ ...f, teamPlayers }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const data = { ...form};

      const response = await (player
        ? ApiClient.put(`players/${player.id}`, data)
        : ApiClient.post(`players`, data));

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refetch();

      snackbar.open('Player has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
