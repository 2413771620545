import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    overflow: 'auto',
    height: '150',
  },
  cardContent: {
    height: 40,
    padding: theme.spacing(1.2),
  },
  grid: {
    padding: theme.spacing(1.2),
  },

  selected: {
    display: 'flex',
    overflow: 'auto',
    height: '150',
    backgroundColor: theme.palette.action.selected,
  },
}));
