import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
  },
  rootDisabled: {
    display: 'flex',
    width: '100%',
    overflow: 'auto',
    backgroundColor: theme.palette.action.disabled,
  },

  chip: {
    '& > *': {
      margin: theme.spacing(0.2),
    },
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
  },
  redCard: {
    backgroundColor: theme.palette.error.light,
  },
  tPenalty: {
    backgroundColor: theme.palette.success.light,
  },
  blueCard: {
    backgroundColor: theme.palette.info.light,
  },
  min2: {
    backgroundColor: theme.palette.action.disabled,
  },

  text: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    padding: theme.spacing(1.2),
  },
  hide: {
    display: 'none',
  },
  officialCard: {
    backgroundColor: theme.palette.grey[300],
  },
}));
