import {DialogProps, DialogContent,  Grid, TextField } from '@material-ui/core';
import moment from 'moment';
import { ApiClient } from 'ApiClient';
import {
  SortableTable,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { FormDialog, SnackbarContext, DatePicker} from 'components';
import { Player, Club } from 'models';
import * as React from 'react';
import { useApiClient, usePrevious } from 'hooks';
import { Autocomplete } from '@material-ui/lab';
import { TransfersTypes } from 'enums';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format } from 'date-fns';
import { DateFormat } from 'config';

interface Props {
  dialogProps: DialogProps;
  refetch: () => void;
  player?: Player;
}

interface TransferForm {
  clubId?: number;
  club?: Club;
  transferType?: number;
  loanDate?: Date;
}

interface TransferOptions {
  key: number;
  label: string;
}

const transferOptions: TransferOptions[] = [
  { key: 1, label: 'Regular Transfer' },
  { key: 2, label: 'Loan' },
];

export const PlayerTransferDialog: React.FC<Props> = ({
  dialogProps,
  player,
  refetch,
}) => {

  const getForm = React.useCallback(function getForm(
    player?: Player,
  ): TransferForm {
    return {
      clubId: undefined,
      transferType: undefined,
      club: player?.club,
      loanDate: undefined
    };
  },
  []);

  const columns: SortableTableHeader[] = [
    { key: 'created', label: 'Transfer Date' },
    { key: 'club', label: 'Club' },
    { key: 'transferType', label: 'Transfer Type' },
    { key: 'loanDate', label: 'End Of Loan Date' },
  ];

  const snackbar = React.useContext(SnackbarContext);
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(getForm(player));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  const [{ data: clubs }] = useApiClient<Club[]>('clubs');

  const rows =
    player?.playerClubHistories?.map((player) => ({
      key: player.id.toString(),
      cells: [
        { key: 'created', display: player.created !== null ? moment(player.created).format('DD/MM/YYYY') : 'N/A' },
        { key: 'club', display: player.club.name },
        { key: 'transferType', display: player.transferType === TransfersTypes.REGULAR_TRANSFER ? 'Regular Transfer' : 'Loan' },
        { key: 'loanDate', display: player.loanDate !== null ? moment(player.loanDate).format('DD/MM/YYYY') : 'N/A' },
      ],
    })) ?? [];

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(player));
    }
  }, [getForm, player, justOpened]);

  return (
    <FormDialog
      title={`Player Transfer with MHA Id - ${player?.mhaId}`}
      submitText="Transfer"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <DialogContent>
        <Grid container>
          <Grid item md={6} xs={12}>
            <TextField
              label="Current Club"
              type="text"
              onChange={(e) => handleChange(e)}
              name="clubId"
              value={form.club?.name || ''}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Autocomplete
              value={ clubs?.find((c) => c.id === form.clubId) ?? null}
              options={clubs?.filter((c) => c.id !== form.club?.id) ?? []}
              getOptionLabel={(option: Club) => option?.name}
              onChange={(e: React.ChangeEvent<{}>, club: Club | null) =>
                handleAutocompleteChange(club?.id ?? null, 'clubId')
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="clubId"
                  variant="standard"
                  label="Club To"
                  required
                />
              )}
            />
          </Grid>
          <Grid item md={6} xs={12}>
          <Autocomplete
            value={
              transferOptions.find((s) => s.key === form.transferType) || null
            }
            options={transferOptions}
            getOptionLabel={(option: TransferOptions) => option?.label ?? ''}
            onChange={(
              e: React.ChangeEvent<{}>,
              option: TransferOptions | null,
            ) =>
              handleAutocompleteChange(option?.key ?? null, 'transferType')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="transferType"
                variant="standard"
                label="Transfer Type"
                required
              />
            )}
          />
          </Grid>
          <Grid item md={6} xs={12}>
          <DatePicker
            name="loanDate"
            label="End Of Loan Date"
            value={form.loanDate || ''}
            onChange={(e: MaterialUiPickersDate) =>
              handleDateOnlyChange(e, 'loanDate')
            }
            fullWidth
            required={form.transferType === TransfersTypes.LOAN}
          />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContent>
        Player History
        <SortableTable
          disableSorting={true}
          columns={columns}
          rows={rows}
          emptyTableText="No player transfer history available yet."
        />
      </DialogContent>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const data = { ...form, playerId: player?.id };
      const response = await ApiClient.post(`players/transfers`, data);

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refetch();

      snackbar.open('Player has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }

  function handleDateOnlyChange(newVal: MaterialUiPickersDate, name: string) {
    setForm((f) => ({
      ...f,
      [name]: newVal && format(newVal, DateFormat.INPUT),
    }));
  }

  function handleAutocompleteChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }
};
