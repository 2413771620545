import { Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ApiClient } from 'ApiClient';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient } from 'hooks';
import { MhaOfficial } from 'models';
import * as React from 'react';
import { SaveMhaOfficialDialog } from './components';

export const MhaOfficials: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);

  const [{ data: mhaOfficials, refetch }] = useApiClient<MhaOfficial[]>('mha-officials');

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedMhaOfficial, setSelectedMhaOfficial] = React.useState<MhaOfficial>();

  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'First Name' },
    { key: 'surname', label: 'Last Name' },
    { key: 'email', label: 'Email Address' },
    { key: 'mobile', label: 'Mobile' },
    { key: 'address', label: 'Address' },
    { key: 'controls', sortable: false },
  ];

  const rows =
    mhaOfficials?.map((mhaOfficial) => ({
      key: mhaOfficial.id.toString(),
      cells: [
        { key: 'name', display: mhaOfficial.name },
        { key: 'surname', display: mhaOfficial.surname ?? '' },
        { key: 'email', display: mhaOfficial.email ?? '' },
        { key: 'mobile', display: mhaOfficial.mobile ?? '' },
        { key: 'address', display: mhaOfficial.address ?? '' },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={(e) => {
                setModalOpen(true);
                setSelectedMhaOfficial(mhaOfficial);
              }}
              onDelete={(e) => {
                setConfirmOpen(true);
                setSelectedMhaOfficial(mhaOfficial);
              }}
            />
          ),
        },
      ],
    })) ?? [];

  return (
    <Paper>
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">MHA Officials</Typography>

        <ResponsiveIconButton
          onClick={(e) => {
            setSelectedMhaOfficial(undefined);
            setModalOpen(true);
          }}
          color="primary"
          icon={AddIcon}
        >
          Add Mha Official
        </ResponsiveIconButton>
      </Toolbar>

      <SortableTable
        columns={columns}
        rows={rows}
        emptyTableText="No MHA Officials available yet."
        emptyTableCreateAction={(e) => setModalOpen(true)}
        disablePagination
      />

      <SaveMhaOfficialDialog
        mhaOfficial={selectedMhaOfficial}
        refetch={refetch}
        dialogProps={{
          open: modalOpen,
          onClose: (e) => setModalOpen(false),
          fullWidth: true,
          maxWidth: 'sm',
        }}
      />

      <ConfirmDialog
        title="Confirm"
        content="Are you sure you would like to delete this MHA Official?"
        onConfirm={deleteMhaOfficial}
        dialogProps={{
          open: confirmOpen,
          onClose: (e) => {
            setConfirmOpen(false);
            setSelectedMhaOfficial(undefined);
          },
        }}
      />
    </Paper>
  );

  async function deleteMhaOfficial() {
    try {
      if (!selectedMhaOfficial) {
        return;
      }

      const response = await ApiClient.delete(`mha-officials/${selectedMhaOfficial.id}`);

      if (!response) {
        return;
      }

      refetch();

      snackbar.open('Mha Official has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
