import {
  DialogProps,
  Grid,
  makeStyles,
  IconButton,
  TextField,
  Toolbar,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ApiClient } from 'ApiClient';
import {
  FormDialog,
  SnackbarContext,
  ResponsiveIconButton,
  EmptyView,
} from 'components';
import { useApiClient, usePrevious } from 'hooks';
import { SeasonCategory, Competition, CompetitionType, Team } from 'models';
import * as React from 'react';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import { CompetitionTypes } from 'enums';

interface Props {
  dialogProps: DialogProps;
  refetch: () => void;
  categoryCompetitions: SeasonCategory;
  competition?: Competition;
}

interface CompetitionForm {
  name?: string;
  rounds?: number;
  knockoutFinalRounds?: number;
  numberOfHalves?: number;
  timePerHalf?: number;
  competitionTypeId?: number;
  teamCompetitions?: {
    id?: number;
    teamId?: number;
    teamName?: string;
  }[];
}

interface Halves {
  key: number;
  label: string;
}

const halves: Halves[] = [
  { key: 2, label: '2' },
  { key: 3, label: '3' },
];

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
    minHeight: 0,
    paddingRight: 0,
    paddingLeft: 0,
  },
  removeIcon: {
    color: theme.palette.danger.main,
  },
  table: {
    marginBottom: theme.spacing(5),
  },
  narrowPadding: {
    padding: theme.spacing(0, 1),
  },
}));

export const SaveCompetitionDialog: React.FC<Props> = ({
  dialogProps,
  categoryCompetitions,
  competition,
  refetch,
}) => {
  const classes = useStyles();
  const snackbar = React.useContext(SnackbarContext);

  const [{ data }] = useApiClient<CompetitionType[]>('competition-types');

  const competitionTypes = data ?? [];

  const [{ data: teams }] = useApiClient<Team[]>('teams');

  const getForm = React.useCallback(function getForm(
    competition?: Competition,
  ): CompetitionForm {
    return {
      name: competition?.name ?? undefined,
      rounds: competition?.rounds ?? undefined,
      knockoutFinalRounds: competition?.knockoutFinalRounds ?? undefined,
      numberOfHalves: competition?.numberOfHalves ?? undefined,
      timePerHalf: competition?.timePerHalf ?? undefined,
      competitionTypeId: competition?.competitionTypeId ?? undefined,
      teamCompetitions: competition?.teamCompetitions?.map((t) => ({
        id: t.id,
        teamId: t.teamId,
        teamName: t.teamName,
      })),
    };
  },
  []);

  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(getForm(competition));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(competition));
    }
  }, [getForm, competition, justOpened]);

  return (
    <FormDialog
      title={`${competition ? 'Edit' : 'Add'} Competition`}
      submitText="Save"
      additionalOnSubmit={() => submit({} as any, true)}
      additionalSubmitText="Save &amp; Generate"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={6}>
          <TextField
            label="Name"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Rounds"
            type="number"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="rounds"
            value={form.rounds || ''}
            required
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Knockout Final rounds"
            type="number"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="knockoutFinalRounds"
            value={form.knockoutFinalRounds || ''}
            required={
              form.competitionTypeId === CompetitionTypes.KNOCKOUT
                ? true
                : false
            }
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            value={
              competitionTypes.find((t) => t.id === form.competitionTypeId) ||
              null
            }
            options={competitionTypes}
            getOptionLabel={(option: CompetitionType) => option?.name ?? ''}
            onChange={(
              e: React.ChangeEvent<{}>,
              competitionType: CompetitionType | null,
            ) =>
              handleAutocompleteChange(
                competitionType?.id ?? null,
                'competitionTypeId',
              )
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="competitionTypeId"
                variant="standard"
                label="Competition Type"
                required
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Autocomplete
            value={
              halves.find((s) => s.key === form.numberOfHalves) || null
            }
            options={halves}
            getOptionLabel={(option: Halves) => option?.label ?? ''}
            onChange={(
              e: React.ChangeEvent<{}>,
              halves: Halves | null,
            ) =>
              handleAutocompleteChange(halves?.key ?? null, 'numberOfHalves')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="numberOfHalves"
                variant="standard"
                label="Number of Halves"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Time Per Half"
            type="number"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="timePerHalf"
            value={form.timePerHalf || ''}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6">Teams Competition</Typography>

            <ResponsiveIconButton
              onClick={onAddTeamCompetition}
              color="primary"
              icon={AddIcon}
            >
              Add Team To Competition
            </ResponsiveIconButton>
          </Toolbar>

          {!form.teamCompetitions || form.teamCompetitions.length === 0 ? (
            <EmptyView>No Teams Competition</EmptyView>
          ) : (
            <Table size="small" className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.narrowPadding}>Team</TableCell>
                  <TableCell className={classes.narrowPadding}>Team name</TableCell>

                  <TableCell padding="none" />
                </TableRow>
              </TableHead>
              <TableBody>
                {form.teamCompetitions.map((v, i) => (
                  <TableRow key={i}>
                    <TableCell className={classes.narrowPadding}>
                      <Autocomplete
                        value={teams?.find((s) => s.id === v.teamId) || null}
                        multiple={false}
                        options={
                          teams?.filter(
                            (t) =>
                              !form.teamCompetitions
                                ?.filter((c) => Boolean(c.teamId))
                                .map((c) => c.teamId)
                                .includes(t.id),
                          ) ?? []
                        }
                        getOptionLabel={(option: Team) => option?.name + (option.teamCategory?.name ? '-' + option.teamCategory?.name : '') ?? ''}
                        onChange={(
                          e: React.ChangeEvent<{}>,
                          team: Team | null,
                        ) => onChangeTeamCompetition(team?.id, 'teamId', i)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="team"
                            variant="standard"
                            label=""
                            inputProps={{
                              ...params.inputProps,
                            }}
                            required={true}
                          />
                        )}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                          label=""
                          type="text"
                          variant="standard"
                          value={v.teamName ?? ''}
                          fullWidth
                          required
                          onChange={(e) =>
                            onChangeTeamCompetition(e.target.value, 'teamName', i)
                          }
                          name="teamName"
                        />
                    </TableCell>

                    <TableCell padding="none">
                      <IconButton
                        className={classes.removeIcon}
                        onClick={(e) => onRemoveTeamCompetition(e, i)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </Grid>
      </Grid>
    </FormDialog>
  );

  function onChangeTeamCompetition(value: any, name: string, i: number) {
    console.log(value);
    if (!form.teamCompetitions) {
      return null;
    }

    const teamCompetitions = form.teamCompetitions.slice();
    const data = teamCompetitions[i];
    switch (name) {
      case 'teamId':
        data[name] = parseInt(value);
        break;
      case 'teamName':
        data[name] = value ?? '';
        break
      default:
        return;
    }

    setForm((f) => ({
      ...f,
      teamCompetitions,
    }));
  }

  function onAddTeamCompetition(e: React.MouseEvent<HTMLElement>) {
    const teamCompetitions = form.teamCompetitions?.slice() ?? [];

    teamCompetitions.push({
      teamId: 0,
      teamName: ''
    });

    setForm((f) => ({
      ...f,
      teamCompetitions,
    }));
  }

  function onRemoveTeamCompetition(
    e: React.MouseEvent<HTMLElement>,
    i: number,
  ) {
    if (!form.teamCompetitions) {
      return null;
    }

    const teamCompetitions = form.teamCompetitions.slice();

    teamCompetitions.splice(i, 1);

    setForm((f) => ({ ...f, teamCompetitions }));
  }

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function handleAutocompleteChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(
    e: React.FormEvent<HTMLFormElement>,
    generate?: boolean,
  ) {
    try {
      setLoading(true);

      const data = { ...form, season_category_id: categoryCompetitions.id };

      const response = await (competition
        ? ApiClient.put(`competitions/${competition.id}`, data)
        : ApiClient.post(`competitions`, data));

      if (!response) {
        return;
      }

      if (generate) {
        await ApiClient.get(
          `matches/generate-competition-schedule/${response.id}`,
        );
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refetch();

      snackbar.open('Competition has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
