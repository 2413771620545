export enum GameControls {
  WARNING = 1,
  MINUTES_2 = 2,
  RED_CARD = 3,
  T_PENALTY = 4,
  BLUE_CARD = 5,
  GOAL = 6,
  M7_GOAL = 7,
  M7_MISS = 8,
  START_1ST_HALF = 9,
  START_2ND_HALF = 10,
  BREAK = 11,
  TEAM_TIME_OUT = 12,
  END_1ST_HALF = 13,
  END_2ND_HALF = 14,
  END_GAME = 15,
  RED_CARD_2MIN = 16,
  START_1ST_HALF_EXTRA_TIME = 17,
  END_1ST_HALF_EXTRA_TIME = 18,
  START_2ND_HALF_EXTRA_TIME = 19,
  END_2ND_HALF_EXTRA_TIME = 20,
  START_PENALTIES = 21,
  EXTRA_TIME_OR_PENALTIES = 22,
  START_3RD_HALF = 23,
  END_3RD_HALF = 24,
}
