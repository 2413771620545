import {
  Box,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import { ApiClient } from 'ApiClient';
import { ResponsiveIconButton, SnackbarContext } from 'components';
import { Match } from 'models';
import { MatchUpdate } from 'models/MatchUpdate';
import { GameControls } from 'enums/GameControls';
import * as React from 'react';
import { Color } from 'views/Score/Score';
import { UpdatesDialog } from './UpdatesDialog';
import { formatUpdateTime } from 'views/Score/lib/ScoreUtility';
const useStyles = makeStyles(() => ({
  inline: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'right',
  },
  cutOut: {
    textDecoration: 'line-through',
  },
  container: {
    maxHeight: 600,
  },
}));
interface Props {
  match: Match;
  color: Color;
  refetch: () => void;
}

export const Updates: React.FC<Props> = ({ match, color, refetch }) => {
  const classes = useStyles();
  const [updatesEdit, setUpdatesEdit] = React.useState(false);
  const [id, setId] = React.useState<number>(-1);
  const snackbar = React.useContext(SnackbarContext);
  const {
    palette: { getContrastText },
  } = useTheme();

  const timePerHalf = match.competition.timePerHalf ?? 30;

  const sortedMatchUpdates = match.matchUpdates.filter((u) => u.matchUpdateTypeId !== GameControls.EXTRA_TIME_OR_PENALTIES).sort((now, next) => {
    return now.updateTime > next.updateTime ? -1 : 1;
  });
  return (
    <React.Fragment>
      <TableContainer component={Paper} className={classes.container}>
        <Table>
          <TableBody>
            {sortedMatchUpdates.map((data, index) => (
              <TableRow key={index}>
                <TableCell align="left">
                  { 
                    data.updateTime === (((timePerHalf * 3) + 11) * 60) ? 'PEN' : formatUpdateTime(data.updateTime).display
                  }
                </TableCell>
                <TableCell align="left" padding="none">
                  <Box
                    style={{
                      textAlign: 'center',
                      backgroundColor: data.teamId
                        ? data.teamId === match.home.teamId
                          ? color.home
                          : color.away
                        : '',
                    }}
                    p={1}
                  >
                    <Typography
                      style={{
                        color: getContrastText(
                          data.teamId === match.home.teamId
                            ? color.home
                            : color.away,
                        ),
                      }}
                      variant="subtitle2"
                    >
                      {data.teamId === match.away.teamId
                        ? match.away.team.matchLineups.find(
                            (m) => m.id === data.matchLineupId,
                          )?.gameNumber
                        : match.home.team.matchLineups.find(
                            (m) => m.id === data.matchLineupId,
                          )?.gameNumber}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell style={{ width: '100%' }} align="left">
                  {data.matchUpdateType.name}
                  {data.matchLineupId ? ' - ' + matchLineupName(data) : ''}
                </TableCell>
                <TableCell>
                  <Box className={classes.inline}>
                    <ResponsiveIconButton
                      color="secondary"
                      icon={Edit}
                      disabled={
                        (data.matchLineupId === null ||
                        data.matchUpdateTypeId === GameControls.RED_CARD_2MIN
                          ? true
                          : false) || match.endDate
                          ? true
                          : false
                      }
                      onClick={(e) => {
                        setUpdatesEdit(true);
                        setId(data.id);
                      }}
                    />
                    <ResponsiveIconButton
                      color="secondary"
                      icon={Delete}
                      disabled={
                        ((data.matchLineupId === null && data.matchUpdateTypeId !== GameControls.TEAM_TIME_OUT) ||
                        data.matchUpdateTypeId === GameControls.RED_CARD_2MIN
                          ? true
                          : false) || match.endDate
                          ? true
                          : false
                      }
                      onClick={(e) => {
                        deleteEntry(data.id);
                      }}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <UpdatesDialog
        match={match}
        id={id}
        color={color}
        refetch={refetch}
        dialogProps={{
          open: updatesEdit,
          onClose: (e) => {
            setUpdatesEdit(false);
          },
          fullWidth: true,
          maxWidth: 'xs',
        }}
      />
    </React.Fragment>
  );

  async function deleteEntry(matchUpdateId: number) {
    try {
      const response = await ApiClient.delete(
        `scores/delete-action/` + matchUpdateId,
      );
      if (response) {
        snackbar.success('Deleted successfully!');
        refetch();
      }
    } catch (error) {
      snackbar.error(error);
    }
  }

  function matchLineupName(matchUpdate: MatchUpdate): string {
    const matchLineup =
      matchUpdate.teamId === match.away.teamId
        ? match.away.team.matchLineups.find(
            (m) => m.id === matchUpdate.matchLineupId,
          )
        : match.home.team.matchLineups.find(
            (m) => m.id === matchUpdate.matchLineupId,
          );
    if (matchLineup?.clubOfficialId) {
      return (
        matchLineup.clubOfficial.surname.toUpperCase() +
        ' ' +
        matchLineup.clubOfficial.name.charAt(0).toUpperCase() +
        '.'
      );
    } else {
      return (
        matchLineup?.player.surname.toUpperCase() +
          ' ' +
          matchLineup?.player.name.charAt(0).toUpperCase() +
          '.' ?? ''
      );
    }
  }
};
