import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ApiClient } from 'ApiClient';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  RouterLink,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient } from 'hooks';
import { SeasonCategory, Competition } from 'models';
import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams, useRouteMatch } from 'react-router';
import { SaveCompetitionDialog } from './components';

interface Params {
  categoryId: string;
}

export const SeasonCompetitions: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { categoryId } = useParams<Params>();

  const snackbar = React.useContext(SnackbarContext);

  const [{ data: categoryCompetitions, refetch }] = useApiClient<
    SeasonCategory
  >(`season-categories/${categoryId}`);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedCompetition, setSelectedCompetition] = React.useState<
    Competition
  >();

  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'rounds', label: 'Number of rounds' },
    { key: 'type', label: 'Competition Type' },
    { key: 'controls', sortable: false },
  ];

  const rows =
    categoryCompetitions?.competitions.map((competition) => ({
      key: competition.id.toString(),
      cells: [
        {
          key: 'name',
          display:
            competition.matches.length > 0 ? (
              <Button
                component={RouterLink}
                to={`/seasons/${categoryCompetitions?.seasonId}/categories/${competition.seasonCategoryId}/competitions/${competition.id}`}
                variant="text"
                size="small"
                color="secondary"
              >
                {competition.name}
              </Button>
            ) : (
              competition.name
            ),
        },
        { key: 'rounds', display: competition.rounds },
        { key: 'type', display: competition.competitionType.name },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={
                competition.matches.length === 0
                  ? (e) => {
                      setModalOpen(true);
                      setSelectedCompetition(competition);
                    }
                  : undefined
              }
              onDelete={(e) => {
                setConfirmOpen(true);
                setSelectedCompetition(competition);
              }}
            />
          ),
        },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      {match && (
        <React.Fragment>
          <BreadcrumbsItem
            to={`/seasons/${categoryCompetitions?.seasonId}/categories`}
          >
            {categoryCompetitions?.season.name}
          </BreadcrumbsItem>
          <BreadcrumbsItem to={match.url}>
            {categoryCompetitions?.name}
          </BreadcrumbsItem>
        </React.Fragment>
      )}

      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Competitions</Typography>

          <ResponsiveIconButton
            onClick={(e) => {
              setSelectedCompetition(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Competition
          </ResponsiveIconButton>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No competitions available yet."
          emptyTableCreateAction={(e) => setModalOpen(true)}
          disablePagination
        />

        {categoryCompetitions && (
          <SaveCompetitionDialog
            categoryCompetitions={categoryCompetitions}
            competition={selectedCompetition}
            refetch={refetch}
            dialogProps={{
              open: modalOpen,
              onClose: (e) => setModalOpen(false),
              fullWidth: true,
              maxWidth: 'sm',
            }}
          />
        )}

        <ConfirmDialog
          title="Confirm"
          content="Are you sure you would like to delete this competition?"
          onConfirm={deleteCompetition}
          dialogProps={{
            open: confirmOpen,
            onClose: (e) => {
              setConfirmOpen(false);
              setSelectedCompetition(undefined);
            },
          }}
        />
      </Paper>
    </React.Fragment>
  );

  async function deleteCompetition() {
    try {
      if (!selectedCompetition) {
        return;
      }

      const response = await ApiClient.delete(
        `competitions/${selectedCompetition.id}`,
      );

      if (!response) {
        return;
      }

      refetch();

      snackbar.open('Competition has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
