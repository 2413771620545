import { Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ApiClient } from 'ApiClient';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient } from 'hooks';
import { Venue } from 'models';
import * as React from 'react';
import { SaveVenueDialog } from './components';

export const Venues: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);

  const [{ data: venues, refetch }] = useApiClient<Venue[]>('venues');

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedVenue, setSelectedVenue] = React.useState<
    Venue
  >();

  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'address', label: 'Address' },
    { key: 'controls', sortable: false },
  ];

  const rows =
    venues?.map((venue) => ({
      key: venue.id.toString(),
      cells: [
        { key: 'name', display: venue.name },
        { key: 'address', display: venue.address },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={(e) => {
                setModalOpen(true);
                setSelectedVenue(venue);
              }}
              onDelete={(e) => {
                setConfirmOpen(true);
                setSelectedVenue(venue);
              }}
            />
          ),
        },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">venues</Typography>

          <ResponsiveIconButton
            onClick={(e) => {
              setSelectedVenue(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Venue
          </ResponsiveIconButton>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No venues available yet."
          emptyTableCreateAction={(e) => setModalOpen(true)}
          disablePagination
        />

        {venues && (
          <SaveVenueDialog
            venue={selectedVenue}
            refetch={refetch}
            dialogProps={{
              open: modalOpen,
              onClose: () => setModalOpen(false),
              fullWidth: true,
              maxWidth: 'sm',
            }}
          />
        )}

        <ConfirmDialog
          title="Confirm"
          content="Are you sure you would like to delete this venue?"
          onConfirm={deleteVenue}
          dialogProps={{
            open: confirmOpen,
            onClose: (e) => {
              setConfirmOpen(false);
              setSelectedVenue(undefined);
            },
          }}
        />
      </Paper>
    </React.Fragment>
  );

  async function deleteVenue() {
    try {
      if (!selectedVenue) {
        return;
      }

      const response = await ApiClient.delete(
        `venues/${selectedVenue.id}`,
      );

      if (!response) {
        return;
      }

      refetch();

      snackbar.open('Venue has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
