import { Box, Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { TeamScore, Updates } from './components';
import { Timer } from './components';
import { useParams, useRouteMatch } from 'react-router';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useApiClient } from 'hooks';
import Cookies from 'js-cookie';
import { Match } from 'models';
import { DelayedLinearProgress } from 'components';

interface Params {
  id: string;
}

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

export interface Color {
  home: string;
  away: string;
}

export interface MatchTimer {
  isPause: boolean;
  isActive: boolean;
  isEnded: boolean;
  isPenalties: boolean;
  displayTime: number;
}

export const Score: React.FC = () => {
  const routeMatch = useRouteMatch<Params>();
  const { id: matchId } = useParams<Params>();
  const [matchState, setMatch] = React.useState<Match>();
  const [{ data: match, loading: dataLoading, refetch }] = useApiClient<Match>(
    `scores/match-data/${matchId}`,
  );

  const classes = useStyles();
  const [color, setColor] = React.useState<Color>({
    away: Cookies.get('rightTeamColor') || '#03C4A1',
    home: Cookies.get('leftTeamColor') || '#C62A88',
  });

  const [matchTimer, setMatchTimer] = React.useState<MatchTimer>({
    isPause: false,
    isActive: false,
    isEnded: false,
    isPenalties: false,
    displayTime: 0,
  });

  React.useEffect(() => {
    setMatch(match);
  }, [match, refetch]);

  return (
    <React.Fragment>
      {routeMatch && match && (
        <BreadcrumbsItem to={routeMatch.url}>{match?.id}</BreadcrumbsItem>
      )}
      {!matchState || dataLoading ? (
        <DelayedLinearProgress />
      ) : (
        <Grid container className={classes.root}>
          <Grid item xs={12} md={6} lg={4}>
            <TeamScore
              match={matchState}
              setColor={setColor}
              color={color}
              refetch={refetch}
              matchTimer={matchTimer}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box pb={2}>
              <Timer
                match={matchState}
                color={color}
                refetch={refetch}
                setMatchTimer={setMatchTimer}
              />
            </Box>
            <Updates match={matchState} color={color} refetch={refetch} />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TeamScore
              rightAlignment
              match={matchState}
              setColor={setColor}
              color={color}
              refetch={refetch}
              matchTimer={matchTimer}
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};
