import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import * as React from 'react';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Breadcrumbs, Header, Sidebar } from './components';
import { getRoutes } from './routes';
import { User } from 'models';
import { useApiClient } from 'hooks';

interface Props {
  componentError?: Error;
}

const useStyles = makeStyles((theme) => ({
  main: {
    height: '100%',
    color: theme.palette.text.primary,
    [theme.breakpoints.up('lg')]: {
      marginLeft:
        window.location.href.indexOf('/match') !== -1 ? 0 : theme.drawerWidth,
    },
  },
  content: {
    ...theme.mixins.gutters(),
  },
  loadingWrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const AppLayout: React.FC<Props> = ({ componentError }) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const [{ data: user }] = useApiClient<User>(`users/with-token`);

  const routes = getRoutes(user?.userTypeId ?? 0);
  const location = useLocation();
  const [scorePage, setScorePage] = React.useState(
    location.pathname.indexOf('/match') !== -1,
  );
  React.useEffect(() => {
    setScorePage(location.pathname.indexOf('/match') !== -1);
  }, [location]);

  const {
    breakpoints: { up },
  } = useTheme();
  const isLg = useMediaQuery(up('lg'));
  const drawerWidth = useTheme().drawerWidth;
  return (
    <React.Fragment>
      <Sidebar
        open={drawerOpen}
        openDrawer={openDrawer}
        closeDrawer={closeDrawer}
        routes={routes}
      />

      <main
        className={classes.main}
        style={{
          marginLeft: !isLg ? 0 : scorePage ? 0 : drawerWidth,
        }}
      >
        {routes && (
          <Switch>
            {routes.map(
              ({
                component: Component,
                render,
                headerTitle,
                ...routeProps
              }) => (
                <Route
                  {...routeProps}
                  key={routeProps.path}
                  render={(componentProps) => (
                    <React.Fragment>
                      <Header onToggleDrawer={toggleDrawer} routes={routes}>
                        {headerTitle}
                      </Header>

                      <BreadcrumbsProvider>
                        <div className={classes.content}>
                          <Breadcrumbs />

                          {Component ? (
                            <Component {...componentProps} />
                          ) : render ? (
                            render(componentProps)
                          ) : null}
                        </div>
                      </BreadcrumbsProvider>
                    </React.Fragment>
                  )}
                />
              ),
            )}

            <Route>
              <Redirect to="/dashboard" />
            </Route>
          </Switch>
        )}
      </main>
    </React.Fragment>
  );

  function toggleDrawer() {
    setDrawerOpen((open) => !open);
  }

  function openDrawer() {
    if (!drawerOpen) {
      toggleDrawer();
    }
  }

  function closeDrawer() {
    if (drawerOpen) {
      toggleDrawer();
    }
  }
};
