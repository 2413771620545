import { DialogProps, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ApiClient } from 'ApiClient';
import { DateTimePicker, FormDialog, SnackbarContext } from 'components';
import { DateTimeFormat } from 'config';
import { format } from 'date-fns';
import { useApiClient, usePrevious } from 'hooks';
import { MatchMhaOfficialType, MhaOfficial, Venue } from 'models';
import { Match } from 'models/Match';
import * as React from 'react';

interface Props {
  dialogProps: DialogProps;
  refetch?: () => void;
  match: Match;
}

interface MatchForm {
  venueId?: number;
  code?: string;
  matchDate?: Date;
  matchOfficials: {
    matchMhaOfficialsTypeId: number;
    officialTypeName: string;
    officialTypeId: number;
    mhaOfficialId: number | null;
    matchId: number;
  }[];
}

export const SaveMatchDialog: React.FC<Props> = ({
  dialogProps,
  match,
  refetch,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const [{ data }] = useApiClient<Venue[]>('venues');
  const [
    { data: mhaOfficialsTypesData, loading: mhaOfficialsLoading },
  ] = useApiClient<MatchMhaOfficialType[]>('match-mha-officials-types');
  const [{ data: mhaOfficialsData }] = useApiClient<MhaOfficial[]>(
    'mha-officials',
  );

  const venues = data ?? [];

  const getForm = React.useCallback(
    function getForm(match: Match): MatchForm {
      return {
        venueId: match.venueId ?? undefined,
        code: match.code ?? undefined,
        matchDate: match.matchDate ?? undefined,
        matchOfficials:
          mhaOfficialsTypesData?.map((type) => {
            const official = match.matchMhaOfficials.find((off) => off.matchMhaOfficialsType.id === type.id)
            return {
              matchMhaOfficialsTypeId: type.id,
              officialTypeName: type.name,
              officialTypeId: type.officialTypeId,
              mhaOfficialId: official ? official.mhaOfficialId : null,
              matchId: match.id,
            }
          }) ?? [],
      };
    },
    [mhaOfficialsTypesData],
  );

  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(getForm(match));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  const prevMhaOfficialLoading = usePrevious(mhaOfficialsLoading);

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(match));
    }
  }, [getForm, match, justOpened]);

  React.useEffect(() => {
    if (prevMhaOfficialLoading !== mhaOfficialsLoading) {
      setForm(getForm(match));
    }
  }, [getForm, prevMhaOfficialLoading, mhaOfficialsLoading, match]);

  return (
    <FormDialog
      title={`${match ? 'Edit' : 'Add'} Competition`}
      submitText="Save"
      onSubmit={submit}
      loading={loading || mhaOfficialsLoading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Code"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="code"
            value={form.code || ''}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={venues.find((v) => v.id === form.venueId) || null}
            options={venues}
            getOptionLabel={(option) => option?.name ?? ''}
            onChange={(e: React.ChangeEvent<{}>, venue: Venue | null) =>
              handleAutocompleteChange(venue?.id ?? null, 'venueId')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="venueId"
                variant="standard"
                label="Venue"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <DateTimePicker
            name="matchDate"
            label="Match Date"
            value={form.matchDate || ''}
            onChange={(e: MaterialUiPickersDate) =>
              handleDateChange(e, 'matchDate')
            }
            fullWidth
            required
          />
        </Grid>
        {form.matchOfficials.map((official, index) => {
          const officials =
            mhaOfficialsData?.filter(
              (o) =>
                // Get officials which match this type (Delegate, Referee, etc)
                o.mhaOfficialTypes
                  .map((t) => t.officialTypeId)
                  .includes(official.officialTypeId) &&
                // Exclude officials which have been already selected
                !form.matchOfficials
                  .filter(
                    (mo) =>
                      !official.mhaOfficialId ||
                      mo.mhaOfficialId !== official.mhaOfficialId,
                  )
                  .map((mo) => mo.mhaOfficialId)
                  .includes(o.id),
            ) ?? [];

          return (
            <Grid item xs={12} md={6} key={index}>
              <Autocomplete
                value={
                  officials.find((o) => o.id === official?.mhaOfficialId) ??
                  null
                }
                options={officials}
                getOptionLabel={(option) => option?.name + " " + option?.surname ?? ''}
                onChange={(
                  e: React.ChangeEvent<{}>,
                  official: MhaOfficial | null,
                ) =>
                  onChangeOfficials(
                    official?.id ?? null,
                    'mhaOfficialId',
                    index,
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="mhaOfficialId"
                    variant="standard"
                    label={official.officialTypeName}

                  />
                )}
              />
            </Grid>
          );
        })}
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function handleAutocompleteChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function handleDateChange(newVal: MaterialUiPickersDate, name: string) {
    setForm((f) => ({
      ...f,
      [name]: newVal && format(newVal, DateTimeFormat.INPUT),
    }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const data = { ...form };

      const response = await ApiClient.put(`matches/${match.id}`, data);

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      if (refetch) {
        refetch();
      }

      snackbar.open('Match details have been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }

  function onChangeOfficials(value: any, name: 'mhaOfficialId', i: number) {
    if (!form.matchOfficials) {
      return null;
    }

    const mhaOfficials = form.matchOfficials.slice();
    const data = mhaOfficials[i];

    switch (name) {
      case 'mhaOfficialId':
        data[name] = parseInt(value);
        break;

      default:
        return;
    }

    setForm((f) => ({ ...f, mhaOfficials }));
  }
};
