import { DialogProps, Grid, TextField, FormControlLabel, Switch } from '@material-ui/core';
import { ApiClient } from 'ApiClient';
import { FormDialog, SnackbarContext } from 'components';
import { useApiClient, usePrevious } from 'hooks';
import { MhaOfficial, OfficialType } from 'models';
import { Autocomplete } from '@material-ui/lab';
import * as React from 'react';

interface Props {
  dialogProps: DialogProps;
  refetch: () => void;
  mhaOfficial?: MhaOfficial;
}

interface MhaOfficialForm {
  name?: string;
  surname?: string;
  mobile?: string;
  address?: string;
  saveAsUser?: number;
  previousSaveAsUser : number;
  email?: string;
  officialTypes?: OfficialType[];
}

export const SaveMhaOfficialDialog: React.FC<Props> = ({
  dialogProps,
  mhaOfficial,
  refetch,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const [{ data }] = useApiClient<OfficialType[]>('officials-types');

  const officialTypes = data ?? [];

  const getForm = React.useCallback(function getForm(mhaOfficial?: MhaOfficial): MhaOfficialForm {
    return {
      name: mhaOfficial?.name ?? undefined,
      surname: mhaOfficial?.surname ?? undefined,
      email: mhaOfficial?.email ?? undefined,
      mobile: mhaOfficial?.mobile ?? undefined,
      saveAsUser: mhaOfficial?.saveAsUser ?? 0,
      previousSaveAsUser: mhaOfficial?.saveAsUser ?? 0,
      address: mhaOfficial?.address ?? undefined,
      officialTypes: mhaOfficial?.mhaOfficialTypes.map((t) => t.officialsType) ?? [],
    };
  }, []);

  
  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(getForm(mhaOfficial));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(mhaOfficial));
    }
  }, [getForm, mhaOfficial, justOpened]);

  return (
    <FormDialog
      title={`${mhaOfficial ? 'Edit' : 'Add'} MHA Official`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="First Name"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Last Name"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="surname"
            value={form.surname || ''}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Mobile"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="mobile"
            value={form.mobile || ''}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Address"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="address"
            value={form.address || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Email Address"
            type="text"
            fullWidth
            disabled={mhaOfficial ? true : false}
            onChange={(e) => handleChange(e)}
            name="email"
            value={form.email || ''}
          />
        </Grid>
        <Grid item xs={6}>
            <Autocomplete
              value={officialTypes.filter((t) => form.officialTypes?.map((ot) => ot.id).includes(t.id) ?? [])}
              multiple
              options={officialTypes}
              getOptionLabel={(option: OfficialType) => option?.name ?? ''}
              onChange={(e, officialTypes) =>
                handleChangeOfficialType(officialTypes)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="officialTypes"
                  variant="standard"
                  label="MHA Officials"
                  inputProps={{
                    ...params.inputProps,
                    required: form.officialTypes?.length === 0
                  }}
                  required={true}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  disabled={form.previousSaveAsUser === 1}
                  checked={form.saveAsUser !== undefined && form.saveAsUser === 1 ? true : false}
                  onChange={(e, checked) => handleSaveUserChange(checked)}
                  name="saveAsUser"
                  color="primary"
                />
              }
              label="Save as user"
            />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleSaveUserChange(checked: Boolean) {
    var value = checked === true ? 1 : 0
    setForm((f) => ({ ...f, saveAsUser: value }));
  }

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function handleChangeOfficialType(officialTypes: OfficialType[]) {
    const newForm = { ...form };
    newForm.officialTypes = officialTypes;
    setForm(newForm);
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const data = { ...form, officialTypes: form.officialTypes?.map((vt) => vt.id)};

      const response = await (mhaOfficial
        ? ApiClient.put(`mha-officials/${mhaOfficial.id}`, data)
        : ApiClient.post(`mha-officials`, data));

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refetch();

      snackbar.open('Mha Official has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
