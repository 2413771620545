import { Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ApiClient } from 'ApiClient';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient } from 'hooks';
import { Club, ClubOfficial } from 'models';
import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams, useRouteMatch } from 'react-router';
import { SaveClubOfficialDialog } from './components';

interface Params {
  id: string;
}

export const ClubOfficials: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { id: clubId } = useParams<Params>();

  const snackbar = React.useContext(SnackbarContext);

  const [{ data: club, refetch }] = useApiClient<Club>(
    `clubs/${clubId}`,
  );

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedClubOfficial, setSelectedClubOfficial] = React.useState<
    ClubOfficial
  >();

  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'surname', label: 'Surname' },
    { key: 'mobile', label: 'Mobile' },
    { key: 'address', label: 'Address' },
    { key: 'email', label: 'Email' },
    { key: 'isPlayer', label: 'isPlayer' },
    { key: 'controls', sortable: false },
  ];

  const rows =
    club?.clubOfficials.map((clubOfficial) => ({
      key: clubOfficial.id.toString(),
      cells: [
        { key: 'name', display: clubOfficial.name },
        { key: 'surname', display: clubOfficial.surname },
        { key: 'mobile', display: clubOfficial.mobile ?? '' },
        { key: 'address', display: clubOfficial.address ?? '' },
        { key: 'email', display: clubOfficial.email ?? '' },
        { key: 'isPlayer', display: clubOfficial.isPlayer ? 'Yes' : 'No' },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={(e) => {
                setModalOpen(true);
                setSelectedClubOfficial(clubOfficial);
              }}
              onDelete={(e) => {
                setConfirmOpen(true);
                setSelectedClubOfficial(clubOfficial);
              }}
            />
          ),
        },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      {match && (
        <BreadcrumbsItem to={match.url}>{club?.name}</BreadcrumbsItem>
      )}

      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Club Official</Typography>

          <ResponsiveIconButton
            onClick={(e) => {
              setSelectedClubOfficial(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Club Official
          </ResponsiveIconButton>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No club officials available yet."
          emptyTableCreateAction={(e) => setModalOpen(true)}
          disablePagination
        />

        {club && (
          <SaveClubOfficialDialog
            club={club}
            clubOfficial={selectedClubOfficial}
            refetch={refetch}
            dialogProps={{
              open: modalOpen,
              onClose: (e) => setModalOpen(false),
              fullWidth: true,
              maxWidth: 'sm',
            }}
          />
        )}

        <ConfirmDialog
          title="Confirm"
          content="Are you sure you would like to delete this club official?"
          onConfirm={deleteClubOfficial}
          dialogProps={{
            open: confirmOpen,
            onClose: (e) => {
              setConfirmOpen(false);
              setSelectedClubOfficial(undefined);
            },
          }}
        />
      </Paper>
    </React.Fragment>
  );

  async function deleteClubOfficial() {
    try {
      if (!selectedClubOfficial) {
        return;
      }

      const response = await ApiClient.delete(
        `club-officials/${selectedClubOfficial.id}`,
      );

      if (!response) {
        return;
      }

      refetch();

      snackbar.open('Club Official has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
