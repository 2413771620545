import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextareaAutosize,
} from '@material-ui/core';
import { DialogProps } from '@material-ui/core/Dialog';
import * as React from 'react';

interface Props {
  setMatchRemarks?: React.Dispatch<React.SetStateAction<string>>;
  showSetRemarks?: boolean;
  title: string;
  dialogProps: DialogProps;
  loading?: boolean;
  content?: React.ReactNode;
  noText?: string;
  yesText?: string;
  onConfirm?(e: React.MouseEvent<HTMLElement>): void;
  onConfirmNo?(e: React.MouseEvent<HTMLElement>): void;
}

export const ConfirmDialog: React.FC<Props> = ({
  title,
  dialogProps,
  loading = false,
  content,
  noText = 'No',
  yesText = 'Yes',
  onConfirm,
  onConfirmNo,
  setMatchRemarks,
  showSetRemarks = false,
}) => {
  return (
  <Dialog {...dialogProps}>
    <DialogTitle>{title}</DialogTitle>

    <DialogContent>
      {typeof content === 'string' ? (
        <DialogContentText>{content}</DialogContentText>
      ) : (
        content
      )}
    </DialogContent>

    {showSetRemarks && (
      <TextareaAutosize
        rowsMin={4}
        placeholder="Enter remarks"
        style={{ width: 290, marginLeft: 23 }}
        onChange={(e) => handleChange(e)}
      />
    )}

    <DialogActions>
      {!onConfirmNo && (
        <Button onClick={dialogProps.onClose} color="default" disabled={loading}>
          {noText}
        </Button>
      )}

      {onConfirmNo && (
        <Button onClick={(e) => {
          if (dialogProps.onClose) {
            dialogProps.onClose(e);
          }

          onConfirmNo(e);
          }} color="default" disabled={loading}>
          {noText}
        </Button>
      )}

      {onConfirm && (
        <Button
          onClick={(e) => {
            if (dialogProps.onClose) {
              dialogProps.onClose(e);
            }

            onConfirm(e);
          }}
          color="primary"
          disabled={loading}
          autoFocus
        >
          {yesText}
        </Button>
      )}
    </DialogActions>
  </Dialog>
  );

  function handleChange({
    target: {value},
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (setMatchRemarks) {
      setMatchRemarks(value);
    }
  }
};