import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Config } from 'config';
import Cookies from 'js-cookie';
import { objectToUrlParams, pascalToUnderscoreCase } from 'lib';
import camelcaseKeys from 'camelcase-keys';

type AxiosJSONResponse<T> = AxiosResponse & {
  success: boolean;
  error?: string;
  data: {
    data: T;
  };
};

class APIClient {
  private api: AxiosInstance;

  constructor() {
    this.api = axios.create();
  }

  public async get<T>(
    path: string,
    queryStringData?: { [key: string]: string | number },
  ) {
    await this.setHeaders('GET');

    const url = `${path}.json${objectToUrlParams(queryStringData)}`;

    const response = await this.api.get<AxiosJSONResponse<T>>(
      `${Config.API_BASE_URL}${url}`,
    );

    return this.getResponse(response);
  }

  public async post<U, T>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig | undefined,
  ) {
    await this.setHeaders('POST');

    const bodyFormData = this.prepareData('POST', data);

    const response = await this.api.post<AxiosJSONResponse<T>>(
      `${Config.API_BASE_URL}${path}.json`,
      bodyFormData,
      config,
    );

    return this.getResponse(response);
  }

  public async put<U, T>(
    path: string,
    data?: any,
    config?: AxiosRequestConfig | undefined,
  ) {
    await this.setHeaders('PUT');

    const params = this.prepareData('PUT', data);

    const response = await this.api.put<AxiosJSONResponse<T>>(
      `${Config.API_BASE_URL}${path}.json`,
      params,
      config,
    );

    return this.getResponse(response);
  }

  public async delete<T>(path: string) {
    await this.setHeaders('DELETE');

    const response = await this.api.delete<AxiosJSONResponse<T>>(
      `${Config.API_BASE_URL}${path}.json`,
    );

    return this.getResponse(response);
  }

  private getResponse<T>(response: AxiosResponse<AxiosJSONResponse<T>>) {
    if (!response.data.success) {
      throw new Error(response.data.error);
    }

    return camelcaseKeys(response.data.data, { deep: true });
  }

  private async setHeaders(method: 'GET' | 'POST' | 'PUT' | 'DELETE') {
    const token = await this.getToken();

    const headers: any = {};

    if (['GET', 'POST', 'PUT'].includes(method)) {
      headers.Accept = 'application/json';
    }

    if (['PUT', 'POST'].includes(method)) {
      headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    if (token) {
      headers.Authorization = 'Bearer ' + token;
    }

    this.api.defaults.headers = headers;
  }

  private async getToken() {
    return Cookies.get('authUser') ?? '';
  }

  private prepareData(method: 'POST' | 'PUT', data: any) {
    const formData = method === 'PUT' ? new URLSearchParams() : new FormData();

    if (!data) {
      return formData;
    }

    Object.keys(data).forEach((k) => {
      const key = pascalToUnderscoreCase(k);

      if (typeof data[k] === 'undefined') {
        return;
      }

      if (Array.isArray(data[k])) {
        data[k].forEach((value: any, index: number) => {
          if (typeof value === 'object') {
            Object.keys(value).forEach((objKey: any) => {
              formData.append(
                `${key}[${index}][${pascalToUnderscoreCase(objKey)}]`,
                value[objKey],
              );
            });
          } else {
            formData.append(`${key}[]`, value);
          }
        });
      } else {
        formData.set(key, data[k]);
      }
    });

    return formData;
  }
}

const singleton = new APIClient();

export { singleton as ApiClient };
