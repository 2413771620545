import {
  Card,
  CardContent,
  Grid,
  Chip,
  Typography,
  CardActionArea,
  Box,
} from '@material-ui/core';
import { SnackbarContext } from 'components';
import { GameControls } from 'enums/GameControls';
import { MatchLineup } from 'models/MatchLineup';
import { MatchUpdate } from 'models/MatchUpdate';
import * as React from 'react';
import {
  getMatchLineupData,
  matchUpdateCall,
  MatchUpdateForm,
} from 'views/Score/lib/ScoreUtility';
import { MatchTimer } from 'views/Score/Score';
import { useStyles } from './styles';
import { Controls } from '../TeamScore';
import classNames from 'classnames';

interface Props {
  bgColor: string;
  gameControl: number;
  setControls: React.Dispatch<React.SetStateAction<Controls>>;
  matchLineup: MatchLineup;
  teamMatchUpdates: MatchUpdate[];
  refetch: () => void;
  matchTimer: MatchTimer;
  readonly?: boolean;
}

export const PlayerScore = React.memo<Props>(
  ({
    bgColor,
    gameControl,
    setControls,
    matchLineup,
    teamMatchUpdates,
    refetch,
    matchTimer,
    readonly,
  }) => {
    const classes = useStyles();
    const snackbar = React.useContext(SnackbarContext);
    const { warning, redCard, blueCard, min2 } = getMatchLineupData(
      matchLineup.matchUpdates, teamMatchUpdates
    );

    const scoreText =
      matchLineup.matchUpdates.filter((u) =>
        [
          GameControls.GOAL,
          GameControls.M7_GOAL,
          GameControls.T_PENALTY,
        ].includes(u.matchUpdateTypeId),
      ).length +
      ' G ' +
      matchLineup.matchUpdates.filter((u) =>
        [GameControls.M7_GOAL, GameControls.M7_MISS].includes(
          u.matchUpdateTypeId,
        ),
      ).length +
      ' / ' +
      matchLineup.matchUpdates.filter((u) =>
        [GameControls.M7_GOAL].includes(u.matchUpdateTypeId),
      ).length +
      ' 7m. ';
    return (
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Card className={readonly ? classes.rootDisabled : classes.root}>
          <CardActionArea
            onClick={(evt) => {
              !readonly && GameControls[gameControl + 1] && cardClick();
            }}
          >
            <CardContent className={classNames({
            [classes.officialCard]: !matchLineup.player,
            [classes.card] : true
            })}>
              <Box className={classes.text}>
                <Typography
                  variant="h5"
                  style={{ color: bgColor, paddingRight: 10 }}
                >
                  {matchLineup.gameNumber}
                </Typography>
                <strong style={{ fontSize: '12px' }}>
                  {matchLineup.player
                    ? matchLineup.player.surname.toUpperCase() + ' ' +matchLineup.player.name.charAt(0).toUpperCase() + '.'
                    : (matchLineup.clubOfficial ? matchLineup.clubOfficial.surname.toUpperCase() : '') + ' ' + (matchLineup.clubOfficial ? matchLineup.clubOfficial.name.charAt(0).toUpperCase() + '.' : '')}
                </strong>
              </Box>
              <Box  className={classes.chip}>
                <Chip
                  size="small"
                  label="Y"
                  variant="outlined"
                  className={warning ? classes.warning : ''}
                />
                {[...Array(3)].map((e, index) => (
                  <Chip
                    key={index}
                    variant="outlined"
                    size="small"
                    label="2"
                    className={min2 >= index + 1 ? classes.min2 : ''}
                    style={{
                      display: matchLineup.clubOfficialId && index > 0 ? 'none' : '',
                    }}
                  />
                ))}
                <Chip
                  size="small"
                  label="D"
                  variant="outlined"
                  className={redCard ? classes.redCard : ''}
                />
                <Chip
                  variant="outlined"
                  size="small"
                  label="DR"
                  className={blueCard ? classes.blueCard : ''}
                  style={{
                    display: matchLineup.clubOfficialId ? 'none' : '',
                  }}
                />
              </Box>
              <Typography
                variant="subtitle1"
                style={{
                  color: bgColor,
                  display: matchLineup.clubOfficialId ? 'none' : '',
                }}
              >
                {scoreText}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    );

    async function cardClick() {
      const data: MatchUpdateForm = {
        teamId: matchLineup.teamId,
        matchLineupId: matchLineup.id,
        matchId: matchLineup.matchId,
        matchUpdateTypeId: gameControl + 1,
        updateTime: matchTimer.displayTime,
      };

      const result = await matchUpdateCall(matchLineup, teamMatchUpdates, data, snackbar);
      if (result) {
        refetch();
      }
      setControls((curVal) => ({
        ...curVal,
        gameControl: -1,
      }));
    }
  },
);
