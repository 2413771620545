import { CompetitionTypes } from 'enums';
import { useApiClient } from 'hooks';
import { Competition } from 'models';
import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  RouteComponentProps,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { AppTabs, DelayedLinearProgress } from '../../components';
import { Fixtures, Knockout, Standings, TopScorerReport } from './components';

export enum MatchTabList {
  FIXTURES = 'fixtures',
  KNOCKOUT = 'knockout',
  STANDINGS = 'standings',
  TOP_SCORER_REPORTS = 'top-scorer-reports',
}

interface Params {
  categoryId: string;
  competitionId: string;
}

export const Matches: React.FC<RouteComponentProps> = (props) => {
  const match = useRouteMatch<Params>();
  const { competitionId } = useParams<Params>();

  const [{ data: competition, loading }] = useApiClient<Competition>(
    `competitions/${competitionId}`,
  );

  let tabs = [];

  if (competition?.competitionType.id === CompetitionTypes.KNOCKOUT) {
    tabs.push({ label: 'Knockout', value: MatchTabList.KNOCKOUT });
  }

  if (competition?.competitionType.id === CompetitionTypes.ROUND_ROBIN) {
    tabs.push({ label: 'Fixtures', value: MatchTabList.FIXTURES });
    tabs.push({ label: 'Standings', value: MatchTabList.STANDINGS });
  }

  tabs.push({
    label: 'Player Statistics',
    value: MatchTabList.TOP_SCORER_REPORTS,
  });

  return (
    <React.Fragment>
      {match && competition && (
        <React.Fragment>
          <BreadcrumbsItem
            to={`/seasons/${competition.seasonCategory.seasonId}/categories`}
          >
            {competition?.seasonCategory.season.name}
          </BreadcrumbsItem>
          <BreadcrumbsItem
            to={`/seasons/${competition.seasonCategory.seasonId}/categories/${competition.seasonCategoryId}/competitions`}
          >
            {competition.seasonCategory.name}
          </BreadcrumbsItem>
          <BreadcrumbsItem to={match.url}>{competition.name}</BreadcrumbsItem>
        </React.Fragment>
      )}

      <DelayedLinearProgress loading={loading} />

      {!loading && !!tabs.length && (
        <AppTabs tabs={tabs}>
          {(activeTab) => {
            switch (activeTab) {
              case MatchTabList.FIXTURES:
                return <Fixtures {...props} />;
              case MatchTabList.KNOCKOUT:
                return <Knockout {...props} />;
              case MatchTabList.STANDINGS:
                return <Standings {...props} />;
              case MatchTabList.TOP_SCORER_REPORTS:
                return <TopScorerReport {...props} />;
              default:
            }
          }}
        </AppTabs>
      )}
    </React.Fragment>
  );
};
