import { DateTimePicker as MaterialDateTimePicker } from '@material-ui/pickers';
import * as React from 'react';
import { DateTimeFormat } from '../../config';

export const DateTimePicker: typeof MaterialDateTimePicker = ({
  value,
  format = DateTimeFormat.SHORT,
  ...rest
}) => (
  <MaterialDateTimePicker
    format={format}
    value={value || null}
    ampm={false}
    placeholder={format && format.toLowerCase()}
    autoOk
    {...rest}
  />
);
