import {
  Grid,
  IconButton,
  Paper,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { Assessment, SportsHandball } from '@material-ui/icons';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  DatePicker,
  DelayedLinearProgress,
  RouterLink,
  SortableTable,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Config, DateTimeFormat } from 'config';
import { format } from 'date-fns';
import { useApiClient, useDebounce } from 'hooks';
import { Match, Team } from 'models';
import * as React from 'react';
import { useReactToPrint } from 'react-to-print';
import { MatchReport } from './components/Report';
import { useStyles } from './styles';
import { SaveMatchLineupDialog } from './components/SaveMatchLineupDialog';

export const ScoreContainer: React.FC = () => {
  const classes = useStyles();
  const [dateFrom, setDateFrom] = React.useState<Date>();
  const [dateTo, setDateTo] = React.useState<Date>();
  const [printId, setPrintId] = React.useState<number>();

  const [openLineup, setOpenLineup] = React.useState(false);
  const [selectedMatch, setSelectedMatch] = React.useState<Match>();
  const [selectedTeam, setSelectedTeam] = React.useState<Team>();

  const refs = React.useRef([] as any);
  const addToRefs = (el: any) => {
    refs.current.push(el);
  };

  const handlePrint = useReactToPrint({
    content: () => refs.current.find((m: any) => m.id === printId?.toString()),
    documentTitle: 'Handball_MatchReport_' + printId,
  });

  React.useEffect(() => {
    if (!printId) {
      return;
    }
    handlePrint && handlePrint();
    setPrintId(undefined);
  }, [printId, handlePrint]);

  const variables = React.useMemo(
    () => ({
      dateFrom: format(dateFrom ?? new Date(), 'yyyy-MM-dd'),
      dateTo: format(dateTo ?? new Date(), 'yyyy-MM-dd'),
    }),
    [dateFrom, dateTo],
  );

  const [{ data, loading }] = useApiClient<Match[]>(
    `scores`,
    useDebounce(variables, 500),
  );
  const columns: SortableTableHeader[] = [
    { key: 'match', label: '', props: { style: { width: '40%' } } },
    {
      key: 'venue',
      label: 'Venue',
      props: { style: { width: '15%' } },
    },
    {
      key: 'date',
      label: 'Match Date',
      props: { style: { width: '15%' } },
    },
    {
      key: 'code',
      label: 'Match Code',
      props: { style: { width: '15%' } },
    },
    {
      key: 'controls',
      sortable: false,
    },
  ];

  const rows =
    data?.map((match, index) => {
      return {
        key: match.id.toString(),
        cells: [
          {
            key: 'match',
            display: (
              <div className={classes.matchContainer}>
                <div className={classes.leftMatch}>
                  <Typography variant="subtitle2">
                    {match.home?.teamName + (match.home?.team.teamCategory ? '-' + match.home?.team.teamCategory?.name : '')}
                  </Typography>
                  {match.home?.team.club.imageUrl ? (
                    <img
                      src={`${Config.IMAGE_CDN_URL}${match.home.team.club.imageUrl}`}
                      alt={`${match.home.team.club.name} logo`}
                      style={{ height: 22, marginLeft: 8 }}
                    />
                  ) : null}
                </div>
                <div>
                  <span className={classes.vsSeperater}>vs</span>
                </div>
                <div className={classes.rightMatch}>
                  {match.away?.team.club.imageUrl ? (
                    <img
                      src={`${Config.IMAGE_CDN_URL}${match.away.team.club.imageUrl}`}
                      alt={`${match.away.team.club.name} logo`}
                      style={{ height: 22, marginRight: 8 }}
                    />
                  ) : null}
                  <Typography variant="subtitle2">
                    {match.away?.teamName + (match.away?.team.teamCategory ? '-' + match.away?.team.teamCategory?.name : '')}
                  </Typography>
                </div>
              </div>
            ),
          },
          { key: 'venue', display: match.venue?.name ?? 'N/A' },
          {
            key: 'date',
            display: match.matchDate
              ? format(new Date(match.matchDate), DateTimeFormat.MEDIUM)
              : 'N/A',
          },
          { key: 'code', display: match.code ?? 'N/A' },
          {
            key: 'controls',
            display: (
              <div style={{ textAlign: 'right' }}>
                <IconButton onClick={() => {
                    setSelectedMatch(match);
                    setSelectedTeam(match.home.team);
                    setOpenLineup(true);
                  }}>
                  <img
                      src={`${Config.IMAGE_CDN_URL}${match.home.team.club.imageUrl}`}
                      alt={`${match.home.team.club.name} logo`}
                      style={{ height: 22}}
                    />
                </IconButton>
                <IconButton onClick={() => {
                    setSelectedMatch(match);
                    setSelectedTeam(match.away.team);
                    setOpenLineup(true);
                  }}>
                <img
                      src={`${Config.IMAGE_CDN_URL}${match.away.team.club.imageUrl}`}
                      alt={`${match.away.team.club.name} logo`}
                      style={{ height: 22}}
                    />
                </IconButton>
                <IconButton
                  component={RouterLink}
                  to={`/score/${match.id}/match`}
                >
                  <SportsHandball />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setPrintId(match.id);
                  }}
                >
                  <Assessment />
                  <div hidden>
                    <div
                      id={match.id.toString()}
                      key={match.id}
                      ref={addToRefs}
                    >
                      <MatchReport matchId={match.id} />
                    </div>
                  </div>
                </IconButton>
              </div>
            ),
          },
        ],
      };
    }) ?? [];

  return (
    <React.Fragment>
      <Paper style={{ marginBottom: 16 }}>
        {loading && <DelayedLinearProgress />}
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Matches</Typography>
        </Toolbar>
        <div className={classes.filtersContainer}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <DatePicker
                label="Date From"
                value={dateFrom || new Date()}
                onChange={(e: MaterialUiPickersDate) =>
                  setDateFrom(e ?? undefined)
                }
                fullWidth
                clearable
                className={classes.filterDateInputs}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <DatePicker
                label="Date To"
                value={dateTo || new Date()}
                onChange={(e: MaterialUiPickersDate) =>
                  setDateTo(e ?? undefined)
                }
                fullWidth
                clearable
                className={classes.filterDateInputs}
              />
            </Grid>
          </Grid>
        </div>
        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No Matches available yet."
          disablePagination
        />
        <SaveMatchLineupDialog
          match={selectedMatch}
          team={selectedTeam}
          dialogProps={{
            open: openLineup,
            onClose: (e) => setOpenLineup(false),
            fullWidth: true,
            maxWidth: 'sm',
          }}
        />
      </Paper>
    </React.Fragment>
  );
};
