import { DialogProps, Grid, TextField } from '@material-ui/core';
import { ApiClient } from 'ApiClient';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { Club } from 'models';
import * as React from 'react';
import { Config } from 'config';

interface Props {
  dialogProps: DialogProps;
  refetch: () => void;
  club?: Club;
}

interface ClubForm {
  name?: string;
  address?: string;
  phone?: string;
  primaryEmail?: string;
  secondaryEmail?: string;
  image?: File;
}

export const SaveClubDialog: React.FC<Props> = ({
  dialogProps,
  club,
  refetch,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const getForm = React.useCallback(function getForm(
    club?: Club,
  ): ClubForm {
    return {
      name: club?.name ?? undefined,
      address: club?.address ?? undefined,
      phone: club?.phone ?? undefined,
      primaryEmail: club?.primaryEmail ?? undefined,
      secondaryEmail: club?.secondaryEmail ?? undefined,
    };
  },
  []);

  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(getForm(club));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(club));
    }
  }, [getForm, club, justOpened]);

  return (
    <FormDialog
      title={`${club ? 'Edit' : 'Add'} Club`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="Name"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Address"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="address"
            value={form.address || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Phone number"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="phone"
            value={form.phone || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Primary Email"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="primaryEmail"
            value={form.primaryEmail || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Secondary Email"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="secondaryEmail"
            value={form.secondaryEmail || ''}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          {club && club.imageUrl && (
            <img
              src={`${Config.IMAGE_CDN_URL}${club.imageUrl}`}
              alt=""
              style={{ height: 65, display: 'block' }}
            />
          )}
          <input
            id="icon-button-file"
            type="file"
            accept="image/*"
            onChange={onFileChange}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { currentTarget } = e;
    const { files } = currentTarget;

    if (!files || !files.length) {
      throw new Error('Select a file.');
    }

    const file = files[0];

    setForm((f) => ({ ...f, image: file }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const data = { ...form };

      const response = await (club
        ? ApiClient.post(`clubs/update/${club.id}`, data)
        : ApiClient.post(`clubs`, data));

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refetch();

      snackbar.open('Club has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
