import { SvgIcon } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SportsHandballIcon from '@material-ui/icons/SportsHandball';
import GavelIcon from '@material-ui/icons/Gavel';
import PeopleIcon from '@material-ui/icons/People';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import { Dvr } from '@material-ui/icons';
import PersonIcon from '@material-ui/icons/Person';
import * as React from 'react';
import { RouteProps } from 'react-router-dom';
import {
  Clubs,
  Dashboard,
  Seasons,
  Users,
  MhaOfficials,
  Venues,
  Players,
  Score,
} from 'views';
import { UserTypes } from 'enums';

interface SubPage {
  path: string;
  headerTitle: string;
  icon: typeof SvgIcon;
  searchTitle: string;
}

// Children not supported by AppLayout
export interface AppRouteProps extends Omit<RouteProps, 'children'> {
  path: string;
  headerTitle: string;
  sidebarName?: React.ReactNode;
  icon: typeof SvgIcon;
  subPages?: SubPage[];
}

export function getRoutes(userTypeId: number) {
  if (userTypeId === UserTypes.ADMIN) {
    const routes: AppRouteProps[] = [];
    routes.push(
      {
        path: '/dashboard',
        headerTitle: 'Dashboard',
        icon: DashboardIcon,
        component: Dashboard,
      },
      {
        path: '/users',
        headerTitle: 'Users',
        icon: PeopleIcon,
        component: Users,
      },
      {
        path: '/seasons',
        headerTitle: 'Seasons',
        icon: SportsHandballIcon,
        component: Seasons,
      },
      {
        path: '/clubs',
        headerTitle: 'Clubs',
        icon: HomeIcon,
        component: Clubs,
      },
      {
        path: '/mha-officials',
        headerTitle: 'MHA Officials',
        icon: GavelIcon,
        component: MhaOfficials,
      },
      {
        path: '/venues',
        headerTitle: 'Venues',
        icon: EmojiEventsIcon,
        component: Venues,
      },
      {
        path: '/players',
        headerTitle: 'Players',
        icon: PersonIcon,
        component: Players,
      },
      {
        path: '/score',
        headerTitle: 'Digital Match Report',
        icon: Dvr,
        component: Score,
      },
    );
    return routes;
  }

  if (userTypeId === UserTypes.SCOREKEEPER) {
    const routes: AppRouteProps[] = [];
    routes.push(
      {
        path: '/dashboard',
        headerTitle: 'Dashboard',
        icon: DashboardIcon,
        component: Dashboard,
      },
      {
        path: '/score',
        headerTitle: 'Digital Match Report',
        icon: Dvr,
        component: Score,
      }
    );
    return routes;
  }
}
