import { DialogProps, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ApiClient } from 'ApiClient';
import { FormDialog, SnackbarContext } from 'components';
import { useApiClient, usePrevious } from 'hooks';
import { User, UserType } from 'models';
import * as React from 'react';

interface Props {
  dialogProps: DialogProps;
  refetch: () => void;
  user?: User;
}

interface UserForm {
  name?: string;
  surname?: string;
  emailAddress?: string;
  userTypeId?: number;
}

export const SaveUserDialog: React.FC<Props> = ({
  dialogProps,
  user,
  refetch,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const [{ data }] = useApiClient<UserType[]>('user-types');

  const userTypes = data ?? [];

  const getForm = React.useCallback(function getForm(user?: User): UserForm {
    return {
      name: user?.name ?? undefined,
      surname: user?.surname ?? undefined,
      emailAddress: user?.emailAddress ?? undefined,
      userTypeId: user?.userTypeId ?? undefined,
    };
  }, []);

  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(getForm(user));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(user));
    }
  }, [getForm, user, justOpened]);

  return (
    <FormDialog
      title={`${user ? 'Edit' : 'Add'} User`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="First Name"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Last Name"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="surname"
            value={form.surname || ''}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Email Address"
            type="text"
            disabled={user ? true : false}
            fullWidth
            onChange={(e) => handleChange(e)}
            name="emailAddress"
            value={form.emailAddress || ''}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={userTypes.find((t) => t.id === form.userTypeId) || null}
            options={userTypes}
            getOptionLabel={(option: UserType) => option?.name ?? ''}
            onChange={(e: React.ChangeEvent<{}>, userType: UserType | null) =>
              handleAutocompleteChange(userType?.id ?? null, 'userTypeId')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="userTypeId"
                variant="standard"
                label="User Type"
                required
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function handleAutocompleteChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const data = { ...form };

      const response = await (user
        ? ApiClient.put(`users/${user.id}`, data)
        : ApiClient.post(`users`, data));

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refetch();

      snackbar.open('User has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
