import { Grid, Paper, Toolbar, Typography } from '@material-ui/core';
import { SortableTable } from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient, useDebounce } from 'hooks';
import { PlayerStats } from 'models';
import React from 'react';
import { useParams } from 'react-router-dom';
import { SelectList } from './components';
import { DelayedLinearProgress } from 'components/DelayedLinearProgress';

interface Params {
  competitionId: string;
}

export const TopScorerReport: React.FC = () => {
  const { competitionId } = useParams<Params>();
  const [team, setTeam] = React.useState<string>('');

  const variables = React.useMemo(
    () => ({
      teamId: team,
    }),
    [team],
  );

  const [{ data: stats, loading, error }] = useApiClient<PlayerStats[]>(
    `competitions/stats/${competitionId}`,
    useDebounce(variables, 500),
  );

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setTeam(event.target.value as string);
  };

  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'First Name' },
    { key: 'surname', label: 'Last Name' },
    { key: 'goals', label: 'Goals' },
    { key: 'warnings', label: 'Yellow Cards' },
    { key: '2min', label: '2 min' },
    { key: 'red', label: 'Red Cards' },
    { key: 'blue', label: 'Blue Cards' },
    { key: 'team', label: 'Team' },
  ];

  const rows =
    stats?.map((stat, idx) => ({
      key: idx.toString(),
      cells: [
        { key: 'name', display: stat.name },
        { key: 'surname', display: stat.surname },
        { key: 'goals', display: Number(stat.goals) },
        { key: 'warnings', display: Number(stat.yellow) },
        { key: '2min', display: Number(stat.min2) },
        { key: 'red', display: Number(stat.red) },
        { key: 'blue', display: Number(stat.blue) },
        { key: 'team', display: stat.team },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      <Paper>
        <DelayedLinearProgress loading={loading} />

        {error && (
          <Typography color="error" component="span">
            {error}
          </Typography>
        )}

        <Toolbar
          style={{
            flexDirection: 'column',
            paddingTop: '1em',
            alignItems: 'flex-start',
          }}
        >
          <Typography variant="h6">Top Scorer</Typography>
          <Grid container style={{ margin: '1em 0' }}>
            <Grid item xs={12} md={6} style={{ paddingLeft: '0' }}>
              <SelectList
                value={team}
                onChange={handleSelectChange}
                inputLabel="Select Team"
              />
            </Grid>
          </Grid>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No statistics available yet."
          disablePagination
        />
      </Paper>
    </React.Fragment>
  );
};
