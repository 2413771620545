import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useApiClient } from 'hooks';
import { Match, Team } from 'models';
import * as React from 'react';
import { Config, DateFormat, TimeFormat } from 'config';
import { format } from 'date-fns';
import { GameControls } from 'enums/GameControls';
import { MatchUpdate } from 'models/MatchUpdate';
import { formatUpdateTime } from 'views/Score/lib/ScoreUtility';
import classNames from 'classnames';
import logo from 'layouts/App/assets/logo_small.png';
interface Props {
  matchId: number;
}

const useStyles = makeStyles((theme) => ({
  narrowPadding: {
    padding: 0,
    color: theme.palette.common.white,
    paddingLeft: 4,
    width: 40,
    textAlign: 'center',
  },
  cellPadding: {
    textAlign: 'center',
  },
  table: {
    marginBottom: theme.spacing(0.2),
    borderStyle: 'solid',
    border: 2,
    "& .MuiTableCell-root": {
      borderLeft: "1px solid rgba(224, 224, 224, 1)"
    }
  },
  remarks: {
    borderStyle: 'solid',
    border: 2,
  },
  title: {
    width: '100%',
    textAlign: 'left',
    padding: theme.spacing(5, 0),
    paddingBottom: 0,
  },
  competitionTitle: {
    width: '100%',
    textAlign: 'left',
  },
  gridContainer: {
    padding: theme.spacing(5, 0),
    textAlign: 'center',
  },
  matchOfficialTitle: {
    fontSize: 12,
    fontWeight: 500,
    paddingLeft: theme.spacing(1.2),
  },
  flexGrid: {
    margin: theme.spacing(3),
  },
  leftLogo: {
    height: 22,
    marginLeft: 8,
  },
  rightLogo: {
    height: 22,
    marginLeft: 8,
    marginRight: theme.spacing(1),
  },

  matchInformation: {
    margin: theme.spacing(0),
    paddingLeft: theme.spacing(4),
  },
  borderTop: {
    borderTop: "2px solid black",
  },
  vsText: {
    padding: theme.spacing(2.5),
  },
  logoImage: {
    width: 35,
    textAlign: 'center',
    padding: theme.spacing(5, 0),
    paddingBottom: 0,
  },
}));

export const MatchReport: React.FC<Props> = ({ matchId }) => {
  const classes = useStyles();
  const [{ data: match }] = useApiClient<Match>(`scores/match-data/${matchId}`);
  const homeGoalInformation = match?.matchUpdates.filter(
    (u) =>
      [GameControls.GOAL, GameControls.M7_GOAL].includes(u.matchUpdateTypeId) &&
      u.teamId === match.home.teamId,
  ).length;

  const guestGoalInformation = match?.matchUpdates.filter(
    (u) =>
      [GameControls.GOAL, GameControls.M7_GOAL].includes(u.matchUpdateTypeId) &&
      u.teamId === match.away.teamId,
  ).length;
  const numberOfHalves = match?.competition.numberOfHalves ?? 2;
  const timePerHalf = match?.competition.timePerHalf ?? 30;

  const teamColumns = (team: Team, teamName: string) => {
    return (
      <TableHead>
        <TableRow
          style={{
            backgroundColor:
              team.id === match?.home.teamId ? '#C62A88' : '#03C4A1',
          }}
        >
          <TableCell
            className={classes.narrowPadding}
          >
            N
          </TableCell>
          <TableCell 
            className={classes.narrowPadding}
            style={{ width: 400 }}
            >
              {teamName}
          </TableCell>
          <TableCell className={classes.narrowPadding}>G</TableCell>
          <TableCell className={classes.narrowPadding}>Y</TableCell>
          <TableCell className={classes.narrowPadding}>2</TableCell>
          <TableCell className={classes.narrowPadding}>2</TableCell>
          <TableCell className={classes.narrowPadding}>2</TableCell>
          <TableCell className={classes.narrowPadding}>D</TableCell>
          <TableCell className={classes.narrowPadding}>DR</TableCell>
          <TableCell className={classes.narrowPadding} style={{ paddingLeft: 4 }}>TP</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  const teamPlayerRows = (team: Team) => {
    return team.matchLineups
      .filter((p) => p.playerId)
      .concat(team.matchLineups.filter((p) => p.clubOfficialId))
      .map((m, index, arr) => {
        const {
          goal,
          warning,
          min2s,
          redCard,
          blueCard,
          tPenalty,
        } = getPlayerDetails(m.matchUpdates);
        const firstOfficialIndex = arr.findIndex((t) => t.clubOfficialId);
        return (
          <TableRow className={classNames({
            [classes.borderTop]: firstOfficialIndex === index,
          })} key={index}>
            <TableCell className={classes.cellPadding}>
              {m.gameNumber}
            </TableCell>
            <TableCell style={{paddingLeft: 2}}>
              {m.playerId
                ?  m?.player.surname.toUpperCase() + ' ' + m?.player?.name
                : m?.clubOfficial?.surname.toUpperCase() + ' ' + m?.clubOfficial?.name}
            </TableCell>
            <TableCell className={classes.cellPadding}>{m.playerId ? goal : ''}</TableCell>
            <TableCell className={classes.cellPadding}>{warning}</TableCell>
            <TableCell className={classes.cellPadding}>{min2s[0] ?? ''}</TableCell>
            <TableCell className={classes.cellPadding}>{min2s[1] ?? ''}</TableCell>
            <TableCell className={classes.cellPadding}>{min2s[2] ?? ''}</TableCell>
            <TableCell className={classes.cellPadding}>{redCard}</TableCell>
            <TableCell className={classes.cellPadding}>{blueCard}</TableCell>
            <TableCell className={classes.cellPadding}>{tPenalty}</TableCell>
          </TableRow>
        );
      });
  };

  const teamTtoCells = (teamId: number) => {
    const tto = match?.matchUpdates.filter(
      (m) =>
        m.teamId === teamId &&
        m.matchUpdateTypeId === GameControls.TEAM_TIME_OUT,
    );
    let data = tto?.map((p, index) => {
      return (
        <TableCell key={index.toString()}>
          {formatUpdateTime(p.updateTime).display}
        </TableCell>
      );
    });

    const dummyCells = data ? 4 - data.length : 4;
    for (let itr = 0; itr < dummyCells; itr++) {
      data?.push(<TableCell key={'dummy' + itr.toString()}></TableCell>);
    }

    return data;
  };

  const m7GivenData = (teamId: number) => {
    const m7 = match?.matchUpdates.filter(
      (m) =>
        m.teamId === teamId &&
        (m.matchUpdateTypeId === GameControls.M7_GOAL ||
          m.matchUpdateTypeId === GameControls.M7_MISS),
    ).length;
    const m7Goal = match?.matchUpdates.filter(
      (m) =>
        m.teamId === teamId && m.matchUpdateTypeId === GameControls.M7_GOAL,
    ).length;
    return m7 + '/' + m7Goal;
  };
  const matchOfficialRows = () => {
    return match?.matchMhaOfficials.map((m, index) => {
      return (
        <TableRow key={index}>
          <TableCell className={classes.matchOfficialTitle}>
            {m.matchMhaOfficialsType?.name}
          </TableCell>
          <TableCell>{m.mhaOfficial?.surname + ' ' + m.mhaOfficial?.name.charAt(0).toUpperCase() + '.'}</TableCell>
        </TableRow>
      );
    });
  };

  const goalFirstHalf = (teamId: number) => {
    return match?.matchUpdates.filter(
      (u) =>
        [GameControls.GOAL, GameControls.M7_GOAL].includes(
          u.matchUpdateTypeId,
        ) &&
        u.teamId === teamId &&
        u.updateTime <= (timePerHalf * 60),
    ).length;
  };

  const goalSecondHalf = (teamId: number) => {
    return match?.matchUpdates.filter(
      (u) =>
        [GameControls.GOAL, GameControls.M7_GOAL].includes(
          u.matchUpdateTypeId,
        ) &&
        u.teamId === teamId &&
        u.updateTime > (timePerHalf * 60) && u.updateTime <= (((timePerHalf * 2)) * 60),
    ).length;
  };

  // const goalFullTime = (teamId: number) => {
  //   return match?.matchUpdates.filter(
  //     (u) =>
  //       [GameControls.GOAL, GameControls.M7_GOAL].includes(
  //         u.matchUpdateTypeId,
  //       ) &&
  //       u.teamId === teamId &&
  //       u.updateTime > (30 * 60) && u.updateTime <= (60 * 60),
  //   ).length;
  // };
  const goalFirstExtraHalf = (teamId: number) => {
    return match?.matchUpdates.filter(
      (u) =>
        [GameControls.GOAL, GameControls.M7_GOAL].includes(
          u.matchUpdateTypeId,
        ) &&
        u.teamId === teamId &&
        u.updateTime > ((timePerHalf * 2) * 60) && u.updateTime <= (((timePerHalf * 2) + 5) * 60),
    ).length;
  };

  const hasPenalties = match?.matchUpdates?.filter((u) =>
    u.matchUpdateTypeId === GameControls.START_PENALTIES
  ).length;

  const hasFirstExtraTime = match?.matchUpdates?.filter((u) =>
    u.matchUpdateTypeId === GameControls.START_1ST_HALF_EXTRA_TIME
  ).length;

  const hasSecondExtraTime = match?.matchUpdates?.filter((u) =>
    u.matchUpdateTypeId === GameControls.START_2ND_HALF_EXTRA_TIME
  ).length;

  const goalSecondExtraHalf = (teamId: number) => {
    return match?.matchUpdates.filter(
      (u) =>
        [GameControls.GOAL, GameControls.M7_GOAL].includes(
          u.matchUpdateTypeId,
        ) &&
        u.teamId === teamId &&
        u.updateTime > (((timePerHalf * 2) + 5) * 60) && u.updateTime <= (((timePerHalf * 2) + 10) * 60),
    ).length;
  };

  const penalties = (teamId: number) => {
    return match?.matchUpdates.filter(
      (u) =>
        [GameControls.M7_GOAL].includes(
          u.matchUpdateTypeId,
        ) &&
        u.teamId === teamId &&
        u.updateTime === (((timePerHalf * 2) + 11) * 60),
    ).length;
  };
  return (
    <React.Fragment>
      <Grid spacing={1} container className={classes.gridContainer}>
        <Grid item xs={2}>
          <img src={logo} alt="logo" className={classes.logoImage} />
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h5" className={classes.title}>
            MATCH REPORT
          </Typography>
          <Typography variant="h6" className={classes.competitionTitle}>
            {match?.competition.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.gridContainer}>
        <Grid item xs={2}>
          <Typography variant="caption">Match ID </Typography>
          <Typography variant="subtitle1">{match?.code}</Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="h6" display="inline">
            {match?.home?.teamName +
              (match?.home?.team.teamCategory
                ? '-' + match?.home?.team.teamCategory?.name
                : '')}
          </Typography>
          {match?.home?.team.club?.imageUrl ? (
            <img
              src={`${Config.IMAGE_CDN_URL}${match?.home.team.club.imageUrl}`}
              alt={`${match?.home.team.club.name} logo`}
              className={classes.leftLogo}
            />
          ) : null}
          <Typography
            variant="subtitle1"
            display="inline"
            className={classes.vsText}
          >
            vs
          </Typography>
          {match?.away?.team.club?.imageUrl ? (
            <img
              src={`${Config.IMAGE_CDN_URL}${match?.away.team.club.imageUrl}`}
              alt={`${match?.away.team.club.name} logo`}
              className={classes.rightLogo}
            />
          ) : null}
          <Typography variant="h6" display="inline">
            {match?.away?.teamName +
              (match?.away?.team.teamCategory
                ? '-' + match?.away?.team.teamCategory?.name
                : '')}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.matchInformation}>
        <Grid item xs={3}>
          <Typography variant="caption">Place </Typography>
          <Typography variant="subtitle1">{match?.venue?.name}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="caption">Date</Typography>
          <Typography variant="subtitle1">
            {match?.matchDate
              ? format(new Date(match?.matchDate), DateFormat.SHORT_DOT)
              : 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="caption">Time</Typography>
          <Typography variant="subtitle1">
            {match?.matchDate
              ? format(new Date(match?.matchDate), TimeFormat.SHORT)
              : 'N/A'}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="caption">Final Result</Typography>
          <Typography variant="subtitle1">
            {homeGoalInformation + ' : ' + guestGoalInformation}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={classes.flexGrid}>
        <Grid item xs={8}>
          {match?.home.team && (
            <Table size="small" className={classes.table} padding="none">
              {teamColumns(match?.home.team, match?.home.teamName)}
              <TableBody>
                {teamPlayerRows(match?.home.team)}
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>TTO</TableCell>
                  {teamTtoCells(match?.home.teamId)}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Grid>
        <Grid item xs={3}>
          <Table size="small" className={classes.table} padding="none">
            <TableBody>
              <TableRow>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: '#C62A88',
                    color: useTheme().palette.common.white,
                  }}
                >
                  Team A
                </TableCell>
                <TableCell
                  align="center"
                  style={{
                    backgroundColor: '#03C4A1',
                    color: useTheme().palette.common.white,
                  }}
                >
                  Team B
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  Half Time
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  {goalFirstHalf(match?.home.teamId ?? 0)}
                </TableCell>
                <TableCell align="center">
                  {goalFirstHalf(match?.away.teamId ?? 0)}
                </TableCell>
              </TableRow>
              {numberOfHalves === 3 && (
                <React.Fragment>
                  <TableRow>
                    <TableCell colSpan={2} align="center">
                      2nd Half Time
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      {goalSecondHalf(match?.home.teamId ?? 0)}
                    </TableCell>
                    <TableCell align="center">
                      {goalSecondHalf(match?.away.teamId ?? 0)}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              )}
              <TableRow>
                <TableCell colSpan={2} align="center">
                  Full Time
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">{homeGoalInformation}</TableCell>
                <TableCell align="center">{guestGoalInformation}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  1st extra time
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  {hasFirstExtraTime !== undefined && hasFirstExtraTime > 0
                    ? goalFirstExtraHalf(match?.home.teamId ?? 0)
                    : '-'}
                </TableCell>
                <TableCell align="center">
                  {hasFirstExtraTime !== undefined && hasFirstExtraTime > 0
                    ? goalFirstExtraHalf(match?.away.teamId ?? 0)
                    : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  2nd extra time
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  {hasSecondExtraTime !== undefined && hasSecondExtraTime > 0
                    ? goalSecondExtraHalf(match?.home.teamId ?? 0)
                    : '-'}
                </TableCell>
                <TableCell align="center">
                  {hasSecondExtraTime !== undefined && hasSecondExtraTime > 0
                    ? goalSecondExtraHalf(match?.away.teamId ?? 0)
                    : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  after 7 metres
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  {hasPenalties !== undefined && hasPenalties > 0
                    ? penalties(match?.home.teamId ?? 0)
                    : '-'}
                </TableCell>
                <TableCell align="center">
                  {hasPenalties !== undefined && hasPenalties > 0
                    ? penalties(match?.away.teamId ?? 0)
                    : '-'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  7m given / goals
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">
                  {m7GivenData(match?.home.teamId ?? 0)}
                </TableCell>
                <TableCell align="center">
                  {' '}
                  {m7GivenData(match?.away.teamId ?? 0)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <br></br>
          <Table size="small" className={classes.table} padding="none">
            <TableBody>
              <TableRow>
                <TableCell colSpan={2} align="center">
                  Officials
                </TableCell>
              </TableRow>
              {matchOfficialRows()}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid container className={classes.flexGrid}>
        <Grid item xs={8}>
          {match?.away.team && (
            <Table size="small" className={classes.table} padding="none">
              {teamColumns(match?.away.team, match?.away.teamName)}
              <TableBody>
                {teamPlayerRows(match?.away.team)}
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>TTO</TableCell>
                  {teamTtoCells(match?.away.teamId)}
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </Grid>
        <Grid item xs={3}>
          <Table className={classes.remarks} padding="none">
            <TableBody>
              <TableRow>
                <TableCell
                  style={{ paddingLeft: 2, paddingTop: 0, marginTop: 0 }}
                >
                  <strong>Remarks:</strong>
                  <br></br>
                  <div style={{ minHeight: 350 }}>{match?.remarks ?? ''}</div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </React.Fragment>
  );

  function getPlayerDetails(matchUpdates: MatchUpdate[]) {
    const goal = matchUpdates.filter((m) =>
      [GameControls.GOAL, GameControls.M7_GOAL].includes(m.matchUpdateTypeId),
    ).length;
    const warning = getMatchUpdates(matchUpdates, GameControls.WARNING);
    const min2s = matchUpdates
      .filter((m) => m.matchUpdateTypeId === GameControls.MINUTES_2)
      .map((m) => formatUpdateTime(m.updateTime ?? 0).display);
    const redCard = getMatchUpdates(matchUpdates, GameControls.RED_CARD);
    const blueCard = getMatchUpdates(matchUpdates, GameControls.BLUE_CARD);
    const tPenalty = getMatchUpdates(matchUpdates, GameControls.T_PENALTY);

    return { goal, warning, min2s, redCard, blueCard, tPenalty };
  }

  function getMatchUpdates(matchUpdates: MatchUpdate[], type: GameControls) {
    const update = matchUpdates.find((m) => m.matchUpdateTypeId === type);

    return update?.updateTime
      ? formatUpdateTime(update.updateTime).display
      : '';
  }
};
