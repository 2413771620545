import {Grid, InputAdornment, Paper, TextField, Toolbar, Typography, makeStyles, } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ApiClient } from 'ApiClient';
import moment from 'moment';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient, useDebounce } from 'hooks';
import { Player } from 'models';
import * as React from 'react';
import { SavePlayerDialog } from './components';
import { PlayerTransferDialog } from './components';
import { DateFormat } from 'config';
import { format } from 'date-fns';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  filtersContainer: {
    ...theme.mixins.gutters(),
    marginBottom: theme.spacing(3),
    paddingBottom: theme.spacing(4),
  },
  filterDateInputs: {
    marginTop: theme.spacing(1.25),
  },
}));

export const Players: React.FC = () => {
  const classes = useStyles();
  const snackbar = React.useContext(SnackbarContext);
  const [textSearch, setTextSearch] = React.useState('');
  const variables = React.useMemo(
    () => ({
      txtSearch: textSearch,
    }),
    [textSearch],
  );

  const [{ data: players, refetch }] = useApiClient<Player[]>(
    `players`,
    useDebounce(variables, 500),
  );

  const [transfer, setTransfer] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedPlayer, setSelectedPlayer] = React.useState<
    Player
  >();

  const columns: SortableTableHeader[] = [
    { key: 'mhaId', label: 'MHA Id' },
    { key: 'mhaRegistrationDate', label: 'MHA Registration Date' },
    { key: 'name', label: 'Name' },
    { key: 'surname', label: 'Surname' },
    { key: 'idCard', label: 'Id Card' },
    { key: 'dob', label: 'DOB' },
    { key: 'playerType', label: 'Registration Type' },
    { key: 'club', label: 'Club' },
    { key: 'controls', sortable: false },
  ];

  const rows =
    players?.map((player) => ({
      key: player.id.toString(),
      cells: [
        { key: 'mhaId', display: player.mhaId },
        { key: 'mhaRegistrationDate', display: moment(player.mhaRegistrationDate).format('DD/MM/YYYY HH:mm:ss') },
        { key: 'name', display: player.name },
        { key: 'surname', display: player.surname },
        { key: 'idCard', display: player.idCard },
        { key: 'dob', display: format(new Date(player.dob), DateFormat.SHORT)},
        { key: 'playerType', display: player.playerRegistrationType.name },
        { key: 'club', display: player.club?.name },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={(e) => {
                setModalOpen(true);
                setSelectedPlayer(player);
              }}
              onTransfer={(e) => {
                setTransfer(true);
                setSelectedPlayer(player);
              }}
              onDelete={(e) => {
                setConfirmOpen(true);
                setSelectedPlayer(player);
              }}
            />
          ),
        },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Players</Typography>

          <ResponsiveIconButton
            onClick={(e) => {
              setSelectedPlayer(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Player
          </ResponsiveIconButton>
        </Toolbar>
        <div className={classes.filtersContainer}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                label="Search"
                placeholder="Search"
                value={textSearch}
                style={{ marginTop: 10 }}
                onChange={(e) => setTextSearch(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                autoFocus
              />
            </Grid>
          </Grid>
        </div>
        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No players available yet."
          emptyTableCreateAction={(e) => setModalOpen(true)}
        />

        <SavePlayerDialog
          player={selectedPlayer}
          refetch={refetch}
          dialogProps={{
            open: modalOpen,
            onClose: (e) => setModalOpen(false),
            fullWidth: true,
            maxWidth: 'sm',
          }}
        />

        <PlayerTransferDialog
          player={selectedPlayer}
          refetch={refetch}
          dialogProps={{
            open: transfer,
            onClose: (e) => setTransfer(false),
            fullWidth: true,
            maxWidth: 'sm',
          }}
        />

        <ConfirmDialog
          title="Confirm"
          content="Are you sure you would like to delete this player?"
          onConfirm={deletePlayer}
          dialogProps={{
            open: confirmOpen,
            onClose: (e) => {
              setConfirmOpen(false);
              setSelectedPlayer(undefined);
            },
          }}
        />
      </Paper>
    </React.Fragment>
  );

  async function deletePlayer() {
    try {
      if (!selectedPlayer) {
        return;
      }

      const response = await ApiClient.delete(
        `players/${selectedPlayer.id}`,
      );

      if (!response) {
        return;
      }

      refetch();

      snackbar.open('Player has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
