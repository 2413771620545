import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { DelayedLinearProgress } from 'components';
import { useApiClient } from 'hooks';
import { TeamCompetition } from 'models';
import React from 'react';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 200,
      width: '100%',
    },
  }),
);

type SelectList = {
  value: string | number;
  label: string;
};

interface Props {
  value: string;
  inputLabel: string;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

interface Params {
  competitionId: string;
}

export const SelectList: React.FC<Props> = ({
  value,
  onChange,
  inputLabel,
}) => {
  const classes = useStyles();
  const { competitionId } = useParams<Params>();

  const [{ data, loading }] = useApiClient<TeamCompetition[]>(
    `competitions/teams/${competitionId}`,
  );

  const teams = React.useMemo(
    () =>
      data?.reduce<SelectList[]>(
        (prev, curr) =>
          prev.concat({ value: curr.teamId, label: curr.teamName }),
        [],
      ) ?? [],
    [data],
  );

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="select-list-label">{inputLabel}</InputLabel>
        <Select
          labelId="select-list-label"
          id="select-list"
          value={value}
          onChange={onChange}
        >
          <DelayedLinearProgress loading={loading} />
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {teams.map((list, idx) => (
            <MenuItem key={idx + list.label} value={list.value}>
              {list.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};
