import { DialogProps, Grid, TextField } from '@material-ui/core';
import { ApiClient } from 'ApiClient';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { Season } from 'models';
import * as React from 'react';

interface Props {
  dialogProps: DialogProps;
  refetch: () => void;
  season?: Season;
}

interface SeasonForm {
  name?: string;
  seasonYear?: string;
}

export const SaveSeasonDialog: React.FC<Props> = ({
  dialogProps,
  season,
  refetch,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const getForm = React.useCallback(function getForm(
    season?: Season,
  ): SeasonForm {
    return {
      name: season?.name ?? undefined,
      seasonYear: season?.seasonYear ?? undefined,
    };
  },
  []);

  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(getForm(season));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(season));
    }
  }, [getForm, season, justOpened]);

  return (
    <FormDialog
      title={`${season ? 'Edit' : 'Add'} Season`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item md={6} xs={12}>
          <TextField
            label="Name"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            label="Year"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="seasonYear"
            value={form.seasonYear || ''}
            required
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const data = { ...form };

      const response = await (season
        ? ApiClient.put(`seasons/${season.id}`, data)
        : ApiClient.post(`seasons`, data));

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refetch();

      snackbar.open('Season has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
