import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ApiClient } from 'ApiClient';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  RouterLink,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient } from 'hooks';
import { Season, SeasonCategory } from 'models';
import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams, useRouteMatch } from 'react-router';
import { SaveSeasonCategoryDialog } from './components';

interface Params {
  id: string;
}

export const SeasonCategories: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { id: seasonId } = useParams<Params>();

  const snackbar = React.useContext(SnackbarContext);

  const [{ data: season, refetch }] = useApiClient<Season>(
    `seasons/${seasonId}`,
  );

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState<
    SeasonCategory
  >();

  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'controls', sortable: false },
  ];

  const rows =
    season?.seasonCategories.map((category) => ({
      key: category.id.toString(),
      cells: [
        {
          key: 'name',
          display: (
            <Button
              component={RouterLink}
              to={`/seasons/${category.seasonId.toString()}/categories/${category.id.toString()}/competitions`}
              variant="text"
              size="small"
              color="secondary"
            >
              {category.name}
            </Button>
          ),
        },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={(e) => {
                setModalOpen(true);
                setSelectedCategory(category);
              }}
              onDelete={(e) => {
                setConfirmOpen(true);
                setSelectedCategory(category);
              }}
            />
          ),
        },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      {match && (
        <BreadcrumbsItem to={match.url}>{season?.name}</BreadcrumbsItem>
      )}

      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Categories</Typography>

          <ResponsiveIconButton
            onClick={(e) => {
              setSelectedCategory(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Category
          </ResponsiveIconButton>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No categories available yet."
          emptyTableCreateAction={(e) => setModalOpen(true)}
          disablePagination
        />

        {season && (
          <SaveSeasonCategoryDialog
            season={season}
            seasonCategory={selectedCategory}
            refetch={refetch}
            dialogProps={{
              open: modalOpen,
              onClose: (e) => setModalOpen(false),
              fullWidth: true,
              maxWidth: 'sm',
            }}
          />
        )}

        <ConfirmDialog
          title="Confirm"
          content="Are you sure you would like to delete this category?"
          onConfirm={deleteCategory}
          dialogProps={{
            open: confirmOpen,
            onClose: (e) => {
              setConfirmOpen(false);
              setSelectedCategory(undefined);
            },
          }}
        />
      </Paper>
    </React.Fragment>
  );

  async function deleteCategory() {
    try {
      if (!selectedCategory) {
        return;
      }

      const response = await ApiClient.delete(
        `season-categories/${selectedCategory.id}`,
      );

      if (!response) {
        return;
      }

      refetch();

      snackbar.open('Category has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
