import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import { ApiClient } from 'ApiClient';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  RouterLink,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient } from 'hooks';
import AddIcon from '@material-ui/icons/Add';
import { Season } from 'models';
import * as React from 'react';
import { SaveSeasonDialog } from './components';

export const SeasonsContainer: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);

  const [{ data, refetch }] = useApiClient<Season[]>('seasons');

  const seasons = data ?? [];

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedSeason, setSelectedSeason] = React.useState<Season>();

  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'year', label: 'Year' },
    { key: 'controls', sortable: false },
  ];

  const rows = seasons.map((season) => ({
    key: season.id.toString(),
    cells: [
      {
        key: 'name',
        display: (
          <Button
            component={RouterLink}
            to={`/seasons/${season.id.toString()}/categories`}
            variant="text"
            size="small"
            color="secondary"
          >
            {season.name}
          </Button>
        ),
      },
      { key: 'year', display: season.seasonYear ?? '' },
      {
        key: 'controls',
        display: (
          <TableControls
            onEdit={(e) => {
              setModalOpen(true);
              setSelectedSeason(season);
            }}
            onDelete={(e) => {
              setConfirmOpen(true);
              setSelectedSeason(season);
            }}
          />
        ),
      },
    ],
  }));

  return (
    <Paper>
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Seasons</Typography>

        <ResponsiveIconButton
          onClick={(e) => {
            setSelectedSeason(undefined);
            setModalOpen(true);
          }}
          color="primary"
          icon={AddIcon}
        >
          Add Season
        </ResponsiveIconButton>
      </Toolbar>

      <SortableTable
        columns={columns}
        rows={rows}
        emptyTableText="No Seasons available yet."
        emptyTableCreateAction={(e) => setModalOpen(true)}
        disablePagination
      />

      <SaveSeasonDialog
        season={selectedSeason}
        refetch={refetch}
        dialogProps={{
          open: modalOpen,
          onClose: (e) => setModalOpen(false),
          fullWidth: true,
          maxWidth: 'sm',
        }}
      />

      <ConfirmDialog
        title="Confirm"
        content="Are you sure you would like to delete this season?"
        onConfirm={deleteSeason}
        dialogProps={{
          open: confirmOpen,
          onClose: (e) => {
            setConfirmOpen(false);
            setSelectedSeason(undefined);
          },
        }}
      />
    </Paper>
  );

  async function deleteSeason() {
    try {
      if (!selectedSeason) {
        return;
      }

      const response = await ApiClient.delete(`seasons/${selectedSeason.id}`);

      if (!response) {
        return;
      }

      refetch();

      snackbar.open('Season has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
