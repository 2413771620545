import { IconButtonProps } from '@material-ui/core/IconButton';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import RefreshIcon from '@material-ui/icons/Refresh';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import RemoveShoppingCartIcon from '@material-ui/icons/RemoveShoppingCart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';
import * as React from 'react';
import {
  TableControlButton,
  TableControlButtonProps,
} from './TableControlButton';

interface Props extends Omit<IconButtonProps, 'onClick'> {
  onGenerate?: React.MouseEventHandler<HTMLElement>;
  onTransfer?: React.MouseEventHandler<HTMLElement>;
  onEdit?: React.MouseEventHandler<HTMLElement>;
  onRefresh?: React.MouseEventHandler<HTMLElement>;
  onToggleSoldOut?: React.MouseEventHandler<HTMLElement>;
  onAssign?: React.MouseEventHandler<HTMLElement>;
  downloadUrl?: string;
  previewUrl?: string;
  onRemoveLink?: React.MouseEventHandler<HTMLElement>;
  onDelete?: React.MouseEventHandler<HTMLElement>;
  generateTitle?: string;
  transferTitle?: string;
  editTitle?: string;
  refreshTitle?: string;
  deleteTitle?: string;
  previewTitle?: string;
  removeTitle?: string;
  downloadTitle?: string;
  outOfStock?: boolean;
  readonly?: boolean;

  // https://github.com/mui-org/material-ui/issues/15827
  component?: React.ElementType;
}

export const TableControls: React.FC<Props> = ({
  onGenerate,
  onTransfer,
  onEdit,
  onRefresh,
  onToggleSoldOut,
  onAssign,
  downloadUrl,
  onDelete,
  previewUrl,
  onRemoveLink,
  readonly,
  outOfStock,
  generateTitle = 'Generate',
  transferTitle = 'Transfer',
  editTitle = 'Edit',
  refreshTitle = 'Refresh',
  deleteTitle = 'Delete',
  downloadTitle = 'Download',
  removeTitle = 'Unlink',
  previewTitle = 'Preview',
  ...iconButtonProps
}) => {
  const controlButtons: TableControlButtonProps[] = [];

  if (onAssign) {
    controlButtons.push({
      Icon: LocalMallIcon,
      tooltipTitle: 'Assign',
      onClick: onAssign,
    });
  }

  if (onGenerate) {
    controlButtons.push({
      Icon: AssignmentReturnedIcon,
      tooltipTitle: generateTitle,
      onClick: onGenerate,
    });
  }

  if (onTransfer) {
    controlButtons.push({
      Icon: TransferWithinAStationIcon,
      tooltipTitle: transferTitle,
      onClick: onTransfer,
    });
  }

  if (onEdit) {
    controlButtons.push({
      Icon: readonly ? VisibilityIcon : EditIcon,
      tooltipTitle: readonly ? 'View' : editTitle,
      onClick: onEdit,
    });
  }

  if (onRefresh) {
    controlButtons.push({
      Icon: RefreshIcon,
      tooltipTitle: refreshTitle,
      onClick: onRefresh,
    });
  }

  if (onToggleSoldOut) {
    controlButtons.push({
      Icon: outOfStock ? RemoveShoppingCartIcon : ShoppingCartIcon,
      tooltipTitle: outOfStock ? 'Not in stock' : 'In stock',
      onClick: onToggleSoldOut,
    });
  }

  if (downloadUrl) {
    controlButtons.push({
      Icon: CloudDownloadIcon,
      tooltipTitle: downloadTitle,
      onClick: () => window.open(downloadUrl, '_blank', 'noopener,noreferrer'),
    });
  }

  if (previewUrl) {
    controlButtons.push({
      Icon: VisibilityIcon,
      tooltipTitle: previewTitle,
      onClick: () => window.open(previewUrl, '_blank', 'noopener,noreferrer'),
    });
  }

  if (onDelete) {
    controlButtons.push({
      Icon: DeleteIcon,
      tooltipTitle: deleteTitle,
      onClick: onDelete,
    });
  }

  if (onRemoveLink) {
    controlButtons.push({
      Icon: LinkOffIcon,
      tooltipTitle: removeTitle,
      onClick: onRemoveLink,
    });
  }

  return (
    <div style={{ textAlign: 'right' }}>
      {controlButtons.map((b, i) => (
        <TableControlButton key={i} {...iconButtonProps} {...b} />
      ))}
    </div>
  );
};
