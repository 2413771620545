import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ApiClient } from 'ApiClient';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  RouterLink,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient } from 'hooks';
import { Club, Team, TeamCategory } from 'models';
import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams, useRouteMatch } from 'react-router';
import { SaveTeamDialog } from './components';

interface Params {
  id: string;
}

export const Teams: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { id: clubId } = useParams<Params>();

  const snackbar = React.useContext(SnackbarContext);

  const[{ data: teamCategories }] = useApiClient<TeamCategory[]>('team-categories');
  const [{ data: club, refetch }] = useApiClient<Club>(
    `clubs/${clubId}`,
  );

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedTeam, setSelectedTeam] = React.useState<
    Team
  >();

  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'category', label: 'Category' },
    { key: 'controls', sortable: false },
  ];

  const rows =
    club?.teams.map((team) => ({
      key: team.id.toString(),
      cells: [
        {
          key: 'name',
          display: (
            <Button
              component={RouterLink}
              to={`/clubs/${team.clubId.toString()}/teams/${team.id.toString()}/players`}
              variant="text"
              size="small"
              color="secondary"
            >
              {team.name}
            </Button>
          ),
        },
        { key: 'category', display: team.teamCategory?.name ?? '' },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={(e) => {
                setModalOpen(true);
                setSelectedTeam(team);
              }}
              onDelete={(e) => {
                setConfirmOpen(true);
                setSelectedTeam(team);
              }}
            />
          ),
        },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      {match && (
        <BreadcrumbsItem to={match.url}>{club?.name}</BreadcrumbsItem>
      )}

      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Teams</Typography>

          <ResponsiveIconButton
            onClick={(e) => {
              setSelectedTeam(undefined);
              setModalOpen(true);
            }}
            color="primary"
            icon={AddIcon}
          >
            Add Team
          </ResponsiveIconButton>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No teams available yet."
          emptyTableCreateAction={(e) => setModalOpen(true)}
          disablePagination
        />

        {club && (
          <SaveTeamDialog
            club={club}
            team={selectedTeam}
            teamCategories={teamCategories}
            refetch={refetch}
            dialogProps={{
              open: modalOpen,
              onClose: (e) => setModalOpen(false),
              fullWidth: true,
              maxWidth: 'sm',
            }}
          />
        )}

        <ConfirmDialog
          title="Confirm"
          content="Are you sure you would like to delete this team?"
          onConfirm={deleteTeam}
          dialogProps={{
            open: confirmOpen,
            onClose: (e) => {
              setConfirmOpen(false);
              setSelectedTeam(undefined);
            },
          }}
        />
      </Paper>
    </React.Fragment>
  );

  async function deleteTeam() {
    try {
      if (!selectedTeam) {
        return;
      }

      const response = await ApiClient.delete(
        `teams/${selectedTeam.id}`,
      );

      if (!response) {
        return;
      }

      refetch();

      snackbar.open('Team has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
