import {
  DialogProps,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { FormDialog, SortableTable, TableControls } from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { Config, DateTimeFormat } from 'config';
import { format } from 'date-fns';
import { usePrevious } from 'hooks';
import { KnockoutSeed, Match } from 'models';
import * as React from 'react';
import { SaveMatchDialog } from 'views/Matches/components/FixturesTab/components';
import { GameControls } from 'enums/GameControls';

interface Props {
  dialogProps: DialogProps;
  knockoutSeed: KnockoutSeed;
  refetch: () => void;
}

const useStyles = makeStyles({
  matchContainer: {
    display: 'flex',
  },
  leftMatch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    minWidth: 200,
  },
  rightMatch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    minWidth: 200,
  },
  vsSeperater: {
    fontSize: 11,
    padding: 20,
  },
});

export const MatchRoundsDialog: React.FC<Props> = ({
  dialogProps,
  knockoutSeed: propKnockoutSeed,
  refetch,
}) => {
  const classes = useStyles();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedMatch, setSelectedMatch] = React.useState<Match>();
  const [knockoutSeed, setKnockoutSeed] = React.useState(propKnockoutSeed);
  const prevKnockoutSeed = usePrevious(propKnockoutSeed);

  React.useEffect(() => {
    if (propKnockoutSeed !== prevKnockoutSeed) {
      setKnockoutSeed(propKnockoutSeed);
    }
  }, [prevKnockoutSeed, propKnockoutSeed]);

  const columns: SortableTableHeader[] = [
    { key: 'match', label: '', props: { style: { width: '40%' } } },
    {
      key: 'venue',
      label: 'Venue',
      props: { style: { width: '15%' } },
    },
    {
      key: 'date',
      label: 'Match Date',
      props: { style: { width: '15%' } },
    },
    { key: 'controls', sortable: false },
  ];

  const hasRounds = knockoutSeed.match.rounds.length > 0;
  const aggreagateScore = Math.ceil(knockoutSeed.match.competition.rounds / 2);

  return (
    <FormDialog
      title={`Match Round ${knockoutSeed.match.matchRound}`}
      submitText=""
      cancelText="Close"
      dialogProps={dialogProps}
    >
      <Toolbar style={{ minHeight: 'auto', padding: 0 }}>
        <Typography variant="h6">Main Match</Typography>
      </Toolbar>

      <SortableTable
        columns={
          hasRounds
            ? [
                { key: 'match', label: '', props: { style: { width: '40%' } } },
                { key: 'controls', sortable: false },
              ]
            : columns
        }
        rows={getMatchRoundRows([knockoutSeed.match], hasRounds, false, knockoutSeed)}
        emptyTableText="No rounds generated"
        tableProps={{ size: 'small' }}
        disableSorting
        disablePagination
      />

      {hasRounds ? (
        <React.Fragment>
          <Toolbar
            style={{
              minHeight: 'auto',
              paddingLeft: 0,
              paddingRight: 0,
              paddingTop: 16,
            }}
          >
            <Typography variant="h6">Rounds</Typography>
          </Toolbar>

          <SortableTable
            columns={columns}
            rows={getMatchRoundRows(knockoutSeed.match.rounds, false, true, knockoutSeed)}
            emptyTableText="No rounds generated"
            tableProps={{ size: 'small' }}
            disableSorting
            disablePagination
          />
        </React.Fragment>
      ) : null}

      {selectedMatch && (
        <SaveMatchDialog
          match={selectedMatch}
          refetch={refetch}
          dialogProps={{
            open: modalOpen,
            onClose: (e) => setModalOpen(false),
            fullWidth: true,
            maxWidth: 'sm',
          }}
        />
      )}
    </FormDialog>
  );

  function getMatchRoundRows(rounds: Match[], hasRounds: boolean = false, roundsPod: boolean = false, knockoutSeed: KnockoutSeed) {
    let homeGoalInformation:any = 0;
    let awayGoalInformation:any = 0;
    return (
      rounds.map((match) => {
        if (roundsPod) {
          if (match.matchUpdates.length > 0) {
            homeGoalInformation = match.matchUpdates.filter(
              (u) =>
                [
                  GameControls.GOAL,
                  GameControls.M7_GOAL
                ].includes(u.matchUpdateTypeId) && u.teamId === match.home.teamId,
            ).length;
            awayGoalInformation = match.matchUpdates.filter(
              (u) =>
                [
                  GameControls.GOAL,
                  GameControls.M7_GOAL
                ].includes(u.matchUpdateTypeId) && u.teamId === match.away.teamId,
            ).length;
          } else {
            if (knockoutSeed.teams[0].score >= aggreagateScore || knockoutSeed.teams[1].score >= aggreagateScore) {
              homeGoalInformation = '-';
              awayGoalInformation = '-';
            }
          }
        } else {
          homeGoalInformation = knockoutSeed.teams[0].score;
          awayGoalInformation = knockoutSeed.teams[1].score;
        }
        const cells: any = [
          {
            key: 'match',
            display: (
              <div className={classes.matchContainer}>
                <div className={classes.leftMatch}>
                  <Typography variant="subtitle2">
                    {match.home?.teamName ?? 'TBD'}
                  </Typography>
                  {match.home?.team.club.imageUrl ? (
                    <img
                      src={`${Config.IMAGE_CDN_URL}${match.home.team.club.imageUrl}`}
                      alt={`${match.home.team.club.name} logo`}
                      style={{ height: 22, marginLeft: 8 }}
                    />
                  ) : null}
                  {(roundsPod && match.matchUpdates.length > 0) || (hasRounds && !roundsPod) || (!hasRounds && !roundsPod)|| (homeGoalInformation === '-') ? (
                    <Typography style={{ paddingLeft: 5, fontWeight: 600 }} variant="subtitle2">
                      {homeGoalInformation}
                    </Typography>
                  ) : null}
                </div>
                <div>
                  <span className={classes.vsSeperater}>vs</span>
                </div>
                <div className={classes.rightMatch}>
                  {(roundsPod && match.matchUpdates.length > 0) || (hasRounds && !roundsPod) || (!hasRounds && !roundsPod) || (awayGoalInformation === '-') ? (
                    <Typography style={{ paddingRight: 5, fontWeight: 600 }} variant="subtitle2">
                      {awayGoalInformation}
                    </Typography>
                  ) : null}
                  {match.away?.team.club.imageUrl ? (
                    <img
                      src={`${Config.IMAGE_CDN_URL}${match.away.team.club.imageUrl}`}
                      alt={`${match.away.team.club.name} logo`}
                      style={{ height: 22, marginRight: 8 }}
                    />
                  ) : null}
                  <Typography variant="subtitle2">
                    {match.away?.teamName ?? 'TBD'}
                  </Typography>
                </div>
              </div>
            ),
          },
        ];

        if (!hasRounds) {
          cells.push(
            { key: 'venue', display: match.venue?.name ?? 'N/A' },
            {
              key: 'date',
              display: match.matchDate
                ? format(new Date(match.matchDate), DateTimeFormat.MEDIUM)
                : 'N/A',
            },
          );
        }

        cells.push({
          key: 'controls',
          display: (
            <TableControls
              disabled={hasRounds}
              onEdit={(e) => {
                setSelectedMatch(match);
                setModalOpen(true);
              }}
            />
          ),
        });

        return {
          key: match.id.toString(),
          cells,
        };
      }) ?? []
    );
  }
};
