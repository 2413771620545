import { DialogProps, Grid, TextField } from '@material-ui/core';
import { FormDialog } from 'components';
import * as React from 'react';
import { TimeState } from '../Timer';

interface Props {
  dialogProps: DialogProps;
  timeState: TimeState;
  setTimeState: React.Dispatch<React.SetStateAction<TimeState>>;
}

export const TimerDialog: React.FC<Props> = ({
  dialogProps,
  timeState,
  setTimeState,
}) => {
  const [sec, setSeconds] = React.useState<number>(timeState.seconds);
  const [min, setMinutes] = React.useState<number>(timeState.minutes);

  React.useEffect(() => {
    setSeconds(timeState.seconds);
    setMinutes(timeState.minutes);
  }, [timeState]);
  return (
    <FormDialog
      title="Edit Timer"
      submitText="Save"
      onSubmit={submit}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12} md={6}>
          <TextField
            label="Minutes"
            type="number"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="minutes"
            value={min}
            required
            InputProps={{
              inputProps: {
                max: 29,
                min: 0,
                step: 1,
              },
            }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Seconds"
            type="number"
            onChange={(e) => handleChange(e)}
            fullWidth
            name="seconds"
            value={sec}
            required
            InputProps={{
              inputProps: { step: 1, min: 0, max: 59 },
            }}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    name === 'seconds' ? setSeconds(Number(value)) : setMinutes(Number(value));
  }

  function submit(e: React.FormEvent<HTMLFormElement>) {
    setTimeState((curVal) => ({
      ...curVal,
      timeInSeconds: Number(sec) + Number(60 * min),
      minutes: min,
      seconds: sec,
    }));
    if (dialogProps.onClose) {
      dialogProps.onClose(e);
    }
  }
};
