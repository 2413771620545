import {Paper, Toolbar, Typography } from '@material-ui/core';
import moment from 'moment';
import {
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient } from 'hooks';
import { Team, Player } from 'models';
import * as React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useParams, useRouteMatch } from 'react-router';
import { SavePlayerDialog } from './../../views/Players/components';

interface Params {
  teamId: string;
}

export const TeamPlayers: React.FC = () => {
  const match = useRouteMatch<Params>();
  const { teamId } = useParams<Params>();

  const [{ data: team, refetch }] = useApiClient<Team>(
    `teams/${teamId}`,
  );

  const [modalOpen, setModalOpen] = React.useState(false);
  const [selectedPlayer, setSelectedPlayer] = React.useState<
    Player
  >();

  const columns: SortableTableHeader[] = [
    { key: 'mhaId', label: 'MHA Id' },
    { key: 'mhaRegistrationDate', label: 'MHA Registration Date' },
    { key: 'name', label: 'Name' },
    { key: 'surname', label: 'Surname' },
    { key: 'idCard', label: 'Id Card' },
    { key: 'playerType', label: 'Registration Type' },
    { key: 'controls', sortable: false },
  ];

  const rows =
    team?.teamPlayers?.filter((x) =>x.player.isActive).map((teamPlayer) => ({
      key: teamPlayer.player?.id.toString(),
      cells: [
        { key: 'mhaId', display: teamPlayer.player.mhaId },
        { key: 'mhaId', display: moment(teamPlayer.player.mhaRegistrationDate).format('DD/MM/YYYY HH:mm:ss') },
        { key: 'name', display: teamPlayer.player.name },
        { key: 'surname', display: teamPlayer.player.surname },
        { key: 'idCard', display: teamPlayer.player.idCard },
        { key: 'playerType', display: teamPlayer.player.playerRegistrationType.name },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={(e) => {
                setModalOpen(true);
                setSelectedPlayer(teamPlayer.player);
              }}
            />
          ),
        },
      ],
    })) ?? [];

  return (
    <React.Fragment>
      {match && (
        <React.Fragment>
        <BreadcrumbsItem to={`/clubs/${team?.clubId}/teams`}>
          {team?.club.name}
       </BreadcrumbsItem>
        <BreadcrumbsItem to={match.url}>{team?.name}</BreadcrumbsItem>
        </React.Fragment>
      )}

      <Paper>
        <Toolbar style={{ justifyContent: 'space-between' }}>
          <Typography variant="h6">Players</Typography>
        </Toolbar>

        <SortableTable
          columns={columns}
          rows={rows}
          emptyTableText="No players available yet."
          emptyTableCreateAction={(e) => setModalOpen(true)}
        />

        <SavePlayerDialog
          player={selectedPlayer}
          refetch={refetch}
          dialogProps={{
            open: modalOpen,
            onClose: (e) => setModalOpen(false),
            fullWidth: true,
            maxWidth: 'sm',
          }}
        />
      </Paper>
    </React.Fragment>
  );
};
