import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { ApiClient } from 'ApiClient';
import { ConfirmDialog, SnackbarContext } from 'components';
import { GameControls } from 'enums/GameControls';
import { CompetitionTypes } from 'enums/CompetitionTypes';
import { Match } from 'models';
import * as React from 'react';
import {
  formatUpdateTime,
  MatchUpdateForm,
} from 'views/Score/lib/ScoreUtility';
import { Color, MatchTimer } from 'views/Score/Score';
import { TimerDialog } from './TimerDialog';
import { usePrevious } from 'hooks';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },

  space: {
    padding: theme.spacing(1.2),
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'column',
  },
  card: { overflow: 'auto' },
}));

export interface TimeState {
  timeInSeconds: number;
  seconds: number;
  minutes: number;
}
interface Props {
  initTime?: number;
  color: Color;
  match: Match;
  refetch: () => void;
  setMatchTimer: React.Dispatch<React.SetStateAction<MatchTimer>>;
}

export const Timer: React.FC<Props> = ({
  initTime,
  color,
  match,
  refetch,
  setMatchTimer,
}) => {
  const snackbar = React.useContext(SnackbarContext);
  const [timeState, setTimeState] = React.useState<TimeState>({
    timeInSeconds: 0,
    minutes: 0,
    seconds: 0,
  });
  const [timerEdit, setTimerEdit] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [isMatchEnded, setIsMatchEnded] = React.useState(
    match.endDate ? true : false,
  );
  const [isPaused, setIsPaused] = React.useState(false);
  const [confirmEnd, setConfirmEnd] = React.useState(false);
  const [matchRemarks, setMatchRemarks] = React.useState('');
  const [confirmHalf, setConfirmHalf] = React.useState(false);
  const [extraTimeOrPenalties, setExtraTimeOrPenalties] = React.useState(false);
  const [isPenalties, setIsPenalties] = React.useState(false);
  const [extraTimeOrPenaltiesChosen, setExtraTimeOrPenaltiesChosen] = React.useState(false);
  const [penaltiesChosen, setPenaltiesChosen] = React.useState(false);
  const homeGoalInformation = match.matchUpdates.filter(
    (u) =>
      [GameControls.GOAL, GameControls.M7_GOAL].includes(u.matchUpdateTypeId) &&
      u.teamId === match.home.teamId,
  ).length;
    
  const awayGoalInformation = match.matchUpdates.filter(
    (u) =>
      [GameControls.GOAL, GameControls.M7_GOAL].includes(u.matchUpdateTypeId) &&
      u.teamId === match.away.teamId,
  ).length;
  const {
    palette: { getContrastText },
  } = useTheme();
  const prevMinute = usePrevious(timeState.minutes);
  const numberOfHalves = match.competition.numberOfHalves;
  const timePerHalf = match.competition.timePerHalf ?? 30;

  React.useEffect(() => {
    if (prevMinute !== timeState.minutes) {
      ApiClient.post(`scores/save-minute`, {minute: timeState.minutes, matchId: match.id});
    }
    
  }, [timeState.minutes, prevMinute, match.id])

  React.useEffect(() => {
    setMatchTimer((curVal) => ({
      ...curVal,
      isPause: isPaused,
      isActive: isActive,
      isEnded: isMatchEnded,
      isPenalties: isPenalties
    }));
  }, [isActive, isPaused, isMatchEnded, isPenalties, setMatchTimer]);

  const endGame = match.endDate ? true : false;

  const half1_started = getMatchState(GameControls.START_1ST_HALF);
  const half1_ended = getMatchState(GameControls.END_1ST_HALF);
  const half2_started = getMatchState(GameControls.START_2ND_HALF);
  const half2_ended = getMatchState(GameControls.END_2ND_HALF);
  const half3_started = getMatchState(GameControls.START_3RD_HALF);
  const half3_ended = getMatchState(GameControls.END_3RD_HALF);
  let half1_extra_started = getMatchState(GameControls.START_1ST_HALF_EXTRA_TIME);
  const [extraTimeChosen, setExtraTimeChosen] = React.useState(half1_extra_started);
  let half1_extra_ended = getMatchState(GameControls.END_1ST_HALF_EXTRA_TIME);
  let half2_extra_started = getMatchState(GameControls.START_2ND_HALF_EXTRA_TIME);
  let half2_extra_ended = getMatchState(GameControls.END_2ND_HALF_EXTRA_TIME);
  let start_penalties = getMatchState(GameControls.START_PENALTIES);
  let extra_time_or_penalties = getMatchState(GameControls.EXTRA_TIME_OR_PENALTIES, half1_extra_started, start_penalties);
  React.useEffect(() => {
    if (half1_extra_started) {
      setExtraTimeChosen(true);
    } else if (start_penalties) {
      setPenaltiesChosen(true);
    }

  }, [half1_extra_started, start_penalties]);
  
  const matchUpdateId = getMatchUpdateId();
  let displayTime = 0;
  if (numberOfHalves === 2) {
    displayTime =  half1_ended && !half2_ended
    ? Number(timePerHalf * 60 + Number(timeState.minutes * 60)) +
    Number(timeState.seconds)
      : half2_ended && !half1_extra_ended && extraTimeChosen ? Number((timePerHalf * 2) * 60 + Number(timeState.minutes * 60)) + Number(timeState.seconds)
      : half1_extra_ended && !half2_extra_ended && extraTimeChosen ? Number(((timePerHalf * 2) + 5) * 60 + Number(timeState.minutes * 60)) + Number(timeState.seconds)
      : half2_extra_ended && !start_penalties ? Number(((timePerHalf * 2) + 10) * 60 + Number(timeState.minutes * 60)) + Number(timeState.seconds)
      : start_penalties || penaltiesChosen ? Number(((timePerHalf * 2) + 11) * 60 + Number(timeState.minutes * 60)) + Number(timeState.seconds)
      : Number(timeState.minutes * 60) + Number(timeState.seconds);
  } else {
    displayTime =  half1_ended && !half2_ended
    ? Number(timePerHalf * 60 + Number(timeState.minutes * 60)) +
    Number(timeState.seconds)
      : half2_ended && !half3_ended ? Number((timePerHalf * 2) * 60 + Number(timeState.minutes * 60)) + Number(timeState.seconds)
      : half3_ended && !half1_extra_ended && extraTimeChosen ? Number((timePerHalf * 3) * 60 + Number(timeState.minutes * 60)) + Number(timeState.seconds)
      : half1_extra_ended && !half2_extra_ended && extraTimeChosen ? Number(((timePerHalf * 3) + 5) * 60 + Number(timeState.minutes * 60)) + Number(timeState.seconds)
      : half2_extra_ended && !start_penalties ? Number(((timePerHalf * 3) + 10) * 60 + Number(timeState.minutes * 60)) + Number(timeState.seconds)
      : start_penalties || penaltiesChosen ? Number(((timePerHalf * 3) + 11) * 60 + Number(timeState.minutes * 60)) + Number(timeState.seconds)
      : Number(timeState.minutes * 60) + Number(timeState.seconds);
  }
  const prevBrackets = usePrevious(matchUpdateId);

  React.useEffect(() => {
    if (
      matchUpdateId === GameControls.END_1ST_HALF ||
      matchUpdateId === GameControls.END_2ND_HALF ||
      matchUpdateId === GameControls.END_3RD_HALF ||
      matchUpdateId === GameControls.END_1ST_HALF_EXTRA_TIME ||
      matchUpdateId === GameControls.END_2ND_HALF_EXTRA_TIME ||
      matchUpdateId === GameControls.EXTRA_TIME_OR_PENALTIES
    ) {
      if (!prevBrackets) {
        setIsActive(true);
        setIsPaused(true);
      }
    }
  }, [matchUpdateId, prevBrackets]);

  React.useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    setMatchTimer((curVal) => ({
      ...curVal,
      displayTime: displayTime,
    }));
    if (isActive && isPaused === false) {
      timeout = setTimeout(() => {
        setTimeState({
          timeInSeconds: timeState.timeInSeconds + 1,
          minutes: Math.floor(timeState.timeInSeconds / 60),
          seconds: timeState.timeInSeconds % 60,
        });
        if ((timeState.timeInSeconds === (timePerHalf * 60) && !half1_extra_started && !half1_extra_ended && !half2_extra_started && !half2_extra_ended) || start_penalties) {
          setIsActive(false);
        } else if (timeState.timeInSeconds === 300 && (half1_extra_started || half1_extra_ended || half2_extra_started || half2_extra_ended)) {
          setIsActive(false);
        }
      }, 1000);
    } else {
      if (timeout) clearTimeout(timeout);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [
    timeState.timeInSeconds,
    isActive,
    isPaused,
    initTime,
    setMatchTimer,
    displayTime,
    half1_extra_started,
    half1_extra_ended,
    half2_extra_started,
    half2_extra_ended,
    start_penalties,
    extra_time_or_penalties,
    timePerHalf
  ]);
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container className={classes.root}>
        <Grid item xs={12} md={4} lg={3} className={classes.space}>
          <Button
            variant="contained"
            disabled={endGame || !isActive}
            onClick={() => {
              setIsPaused(true);
              setTimerEdit(true);
            }}
          >
            Time Correction
          </Button>
          <Typography
            style={{
              color: color.home,
              fontSize: '55px',
            }}
            align={'center'}
            variant="h4"
          >
            {homeGoalInformation}
          </Typography>
          <Typography
            style={{
              color: color.home,
              fontSize: '12px',
            }}
            align={'center'}
            variant="subtitle2"
          >
            {match.matchUpdates.filter((x)=> x.matchUpdateTypeId === GameControls.TEAM_TIME_OUT && x.teamId === match.home.teamId).map((y) => { return formatUpdateTime(y.updateTime).display}).join(',')}
          </Typography>
          <Button
            variant="contained"
            startIcon={<ChevronLeft />}
            disabled={endGame || !isPaused}
            style={{
              backgroundColor: color.home,
              color: getContrastText(color.home),
            }}
            onClick={() => {
              handleTTOClick(true);
            }}
          >
            TTO
          </Button>
        </Grid>

        <Grid item xs={12} md={4} lg={6} className={classes.space}>
          <Box pb={2}>
            <Card className={classes.card}>
              <CardContent>
                <CardActionArea
                  style={{ padding: 0 }}
                  onClick={(evt) => {
                    handlePauseResume();
                  }}
                >
                  <Typography
                    align="center"
                    color={
                      !isPaused && isActive ? 'textPrimary' : 'textSecondary'
                    }
                    variant="h1"
                  >
                    {timeState.minutes < 10
                      ? '0' + timeState.minutes
                      : timeState.minutes}
                    :
                    {timeState.seconds < 10
                      ? '0' + timeState.seconds
                      : timeState.seconds}
                  </Typography>

                  {isActive && isPaused && (
                    <Typography variant="subtitle2" align="center">
                      PAUSED
                    </Typography>
                  )}
                  {!isActive && (
                    <Typography variant="subtitle2" align="center">
                      STOPPED
                    </Typography>
                  )}
                  {isActive && !isPaused && (
                    <Typography variant="subtitle2" align="center">
                      RUNNING
                    </Typography>
                  )}
                </CardActionArea>
              </CardContent>
            </Card>
          </Box>
          <Button
            variant="contained"
            onClick={() => {
              if (
                matchUpdateId === GameControls.END_1ST_HALF ||
                matchUpdateId === GameControls.END_2ND_HALF ||
                matchUpdateId === GameControls.END_3RD_HALF ||
                matchUpdateId === GameControls.END_1ST_HALF_EXTRA_TIME ||
                matchUpdateId === GameControls.END_2ND_HALF_EXTRA_TIME ||
                matchUpdateId === GameControls.START_1ST_HALF_EXTRA_TIME ||
                matchUpdateId === GameControls.START_2ND_HALF_EXTRA_TIME
              ) {
                setConfirmHalf(true);
              } else if (matchUpdateId === GameControls.EXTRA_TIME_OR_PENALTIES) {
                setExtraTimeOrPenalties(true);
              } else {
                handleStartStop();
              }
            }}
            disabled={(((half2_ended && numberOfHalves === 2) || (half3_ended && numberOfHalves === 3)) && match.competition.competitionTypeId === CompetitionTypes.ROUND_ROBIN) || endGame || start_penalties}
          >
            {endGame
              ? 'End Game'
              : !half1_started && !half1_ended
              ? 'Start 1st Half'
              : half1_ended && !half2_started
              ? 'Start 2nd Half'
              : half1_started && !half1_ended
              ? 'End 1st Half'
              : half2_started && !half2_ended
              ? 'End 2nd Half'
              : half2_ended && !half3_started && numberOfHalves === 3
              ? 'Start 3rd Half'
              : half3_started && !half3_ended && numberOfHalves === 3
              ? 'End 3rd Half'
              : match.competition.competitionTypeId === CompetitionTypes.KNOCKOUT && ((half2_ended && numberOfHalves === 2) || (half3_ended && numberOfHalves === 3)) && !half1_extra_started && !extra_time_or_penalties
              ? 'Extra Time / 7m Throws'
              : match.competition.competitionTypeId === CompetitionTypes.KNOCKOUT && ((half2_ended && numberOfHalves === 2) || (half3_ended && numberOfHalves === 3)) && !half1_extra_started && extra_time_or_penalties && extraTimeChosen
              ? 'Start 1st Half Extra Time'
              : match.competition.competitionTypeId === CompetitionTypes.KNOCKOUT && half1_extra_started && !half1_extra_ended && extraTimeChosen
              ? 'End 1st Half Extra Time'
              : match.competition.competitionTypeId === CompetitionTypes.KNOCKOUT && half1_extra_ended && !half2_extra_started  && extraTimeChosen
              ? 'Start 2nd Half Extra Time'
              : match.competition.competitionTypeId === CompetitionTypes.KNOCKOUT && half2_extra_started && !half2_extra_ended && extraTimeChosen
              ? 'End 2nd Half Extra Time'
              : match.competition.competitionTypeId === CompetitionTypes.KNOCKOUT && ((half2_extra_ended && !start_penalties && extra_time_or_penalties) || (!start_penalties && penaltiesChosen))
              ? 'Start 7m Throws'
              : 'End Game'}
          </Button>
        </Grid>
        <Grid item xs={12} md={4} lg={3} className={classes.space}>
          <Button
            variant="contained"
            disabled={endGame || ((!half2_ended && numberOfHalves === 2) || (!half3_ended && numberOfHalves === 3))}
            onClick={() => {
              setConfirmEnd(true);
            }}
          >
            End Game
          </Button>
          <Typography
            style={{
              color: color.away,
              fontSize: '55px',
            }}
            align={'center'}
            variant="h4"
          >
            {awayGoalInformation}
          </Typography>
          <Typography
            style={{
              color: color.away,
              fontSize: '12px',
            }}
            align={'center'}
            variant="subtitle2"
          >
            {match.matchUpdates.filter((x)=> x.matchUpdateTypeId === GameControls.TEAM_TIME_OUT && x.teamId === match.away.teamId).map((y) => { return formatUpdateTime(y.updateTime).display}).join(',')}
          </Typography>
          <Button
            variant="contained"
            endIcon={<ChevronRight />}
            disabled={endGame || !isPaused}
            style={{
              backgroundColor: color.away,
              color: getContrastText(color.away),
            }}
            onClick={() => {
              handleTTOClick();
            }}
          >
            TTO
          </Button>
        </Grid>
      </Grid>
      <TimerDialog
        timeState={timeState}
        setTimeState={setTimeState}
        dialogProps={{
          open: timerEdit,
          onClose: (e) => setTimerEdit(false),
          fullWidth: true,
          maxWidth: 'xs',
        }}
      />
      <ConfirmDialog
        setMatchRemarks={setMatchRemarks}
        showSetRemarks={true}
        title="Confirm"
        content="Are you sure you would like to end the game?"
        onConfirm={() => {
          handleEndGame();
        }}
        dialogProps={{
          open: confirmEnd,
          onClose: (e) => {
            setConfirmEnd(false);
          },
        }}
      />
      <ConfirmDialog
        title="Confirm"
        content="Are you sure you would like to proceed with this action?"
        onConfirm={() => {
          handleStartStop();
        }}
        dialogProps={{
          open: confirmHalf,
          onClose: (e) => {
            setConfirmHalf(false);
          },
        }}
      />

      <ConfirmDialog
        title="Confirm"
        content="Extra Time or 7m Throws?"
        yesText={'7m Throws'}
        noText={'Extra Time'}
        onConfirm={() => {
          setPenaltiesChosen(true);
          setExtraTimeOrPenaltiesChosen(true);
          handleStartStop();
        }}
        onConfirmNo={() => {
          setPenaltiesChosen(false);
          setExtraTimeChosen(true);
          setExtraTimeOrPenaltiesChosen(true);
          handleStartStop();
        }}
        dialogProps={{
          open: extraTimeOrPenalties,
          onClose: (e) => {
            setExtraTimeOrPenalties(false);
          },
        }}
      />
    </React.Fragment>
  );
  function handlePauseResume() {
    if (isActive) {
      setIsPaused(!isPaused);
    } else {
      setIsPaused(false);
    }
  }

  async function handleStartStop() {
    try {
      timeState.timeInSeconds = 0;
      timeState.seconds = 0;
      timeState.minutes = 0;
      setIsPaused(false);
      if (
        [
          GameControls.END_1ST_HALF,
          GameControls.END_2ND_HALF,
          GameControls.END_3RD_HALF,
          GameControls.END_1ST_HALF_EXTRA_TIME,
          GameControls.END_2ND_HALF_EXTRA_TIME,
          GameControls.START_PENALTIES,
          GameControls.END_GAME,
          GameControls.EXTRA_TIME_OR_PENALTIES,
        ].includes(matchUpdateId)
      ) {
        setIsActive(false);
      } else {
        setIsActive(!isActive);
      }

      const form: MatchUpdateForm = {
        matchId: match.id,
        matchUpdateTypeId: matchUpdateId,
        updateTime: displayTime,
      };

      const response = await ApiClient.post(`scores/add-action`, form);
      if (response) {
        refetch();
      }
    } catch (error) {
      snackbar.error(error);
    }
  }

  async function handleEndGame() {
    try {
      setConfirmEnd(false);
      timeState.timeInSeconds = 0;
      timeState.seconds = 0;
      timeState.minutes = 0;
      setIsPaused(false);
      setIsActive(false);

      const form = {
        remarks: matchRemarks
      };

      const response = await ApiClient.put(`scores/end-match/` + match.id, form);
      if (response) {
        setIsMatchEnded(true);
        refetch();
      }
    } catch (error) {
      snackbar.error(error);
    }
  }

  async function handleTTOClick(isHomeTeam?: boolean) {
    try {
      let ttoCount: number;

      if (matchUpdateId === GameControls.END_1ST_HALF) {
        ttoCount = match.matchUpdates.filter(
          (u) =>
            u.teamId === (isHomeTeam ? match.home.teamId : match.away.teamId) &&
            u.matchUpdateTypeId === GameControls.TEAM_TIME_OUT &&
            formatUpdateTime(u.updateTime).minutes < timePerHalf,
        ).length;
      } else if (matchUpdateId === GameControls.END_2ND_HALF) {
        ttoCount = match.matchUpdates.filter(
          (u) =>
            u.teamId === (isHomeTeam ? match.home.teamId : match.away.teamId) &&
            u.matchUpdateTypeId === GameControls.TEAM_TIME_OUT &&
            formatUpdateTime(u.updateTime).minutes >= timePerHalf && formatUpdateTime(u.updateTime).minutes <= (timePerHalf * 2),
        ).length;
      } else if (matchUpdateId === GameControls.END_3RD_HALF) {
        ttoCount = match.matchUpdates.filter(
          (u) =>
            u.teamId === (isHomeTeam ? match.home.teamId : match.away.teamId) &&
            u.matchUpdateTypeId === GameControls.TEAM_TIME_OUT &&
            formatUpdateTime(u.updateTime).minutes > (timePerHalf * 2) ,
        ).length;
      } else {
        snackbar.error('Error occured.');
        return;
      }

      if (ttoCount >= 2) {
        snackbar.error('Error occured, already assigned TTO to team 2 times.');
        return;
      }
      setIsPaused(true);
      const form: MatchUpdateForm = {
        matchId: match.id,
        teamId: isHomeTeam ? match.home.teamId : match.away.teamId,
        matchUpdateTypeId: GameControls.TEAM_TIME_OUT,
        updateTime: displayTime,
      };

      const response = await ApiClient.post(`scores/add-action`, form);
      if (response) {
        refetch();
      }
    } catch (error) {
      snackbar.error(error);
    }
  }

  function getMatchState(control: GameControls, extra: boolean = false, pen:boolean = false): boolean {
    if (
      match.matchUpdates.filter((u) => [control].includes(u.matchUpdateTypeId))
        .length > 0
    ) {
      if (control === GameControls.EXTRA_TIME_OR_PENALTIES) {
        if (!extra && !pen && !extraTimeOrPenaltiesChosen) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  function getMatchUpdateId(): GameControls {
    if (endGame) {
      return GameControls.END_GAME;
    } else if (!half1_started && !half1_ended) {
      return GameControls.START_1ST_HALF;
    } else if (half1_ended && !half2_started) {
      return GameControls.START_2ND_HALF;
    } else if (half1_started && !half1_ended) {
      return GameControls.END_1ST_HALF;
    } else if (half2_started && !half2_ended) {
      return GameControls.END_2ND_HALF;
    } else if (half2_ended && !half3_started && numberOfHalves === 3) {
      return GameControls.START_3RD_HALF;
    } else if (half3_started && !half3_ended && numberOfHalves === 3) {
      return GameControls.END_3RD_HALF;
    } else if (((half2_ended && numberOfHalves === 2) || (numberOfHalves === 3 && half3_ended)) && !extraTimeOrPenaltiesChosen && !half1_extra_started && !start_penalties) {
      return GameControls.EXTRA_TIME_OR_PENALTIES;
    } else if (((half2_ended && numberOfHalves === 2) || (numberOfHalves === 3 && half3_ended)) && !half1_extra_started && extraTimeChosen)  {
      return GameControls.START_1ST_HALF_EXTRA_TIME;
    } else if (half1_extra_started && !half1_extra_ended && extraTimeChosen)  {
      return GameControls.END_1ST_HALF_EXTRA_TIME;
    } else if (half1_extra_ended && !half2_extra_started && extraTimeChosen)  {
      return GameControls.START_2ND_HALF_EXTRA_TIME;
    } else if (half2_extra_started && !half2_extra_ended && extraTimeChosen)  {
      return GameControls.END_2ND_HALF_EXTRA_TIME;
    } else if ((half2_extra_ended && !start_penalties) || (start_penalties) || penaltiesChosen)  {
      if (!isPenalties) {
        setIsPenalties(true);
      }
      return GameControls.START_PENALTIES;
    } else {
      return GameControls.END_GAME;
    }
  }
};
