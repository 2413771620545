import { Typography } from '@material-ui/core';
import { DateTimeFormat } from 'config';
import { format } from 'date-fns';
import { usePrevious } from 'hooks';
import { KnockoutBracket, KnockoutSeed } from 'models';
import * as React from 'react';
import { Bracket, Seed, SeedItem, SeedTeam, SeedTime } from 'react-brackets';
import { MatchRoundsDialog } from './components';

interface Props {
  brackets: KnockoutBracket[];
  refetch: () => void;
}

export const Tournament: React.FC<Props> = ({ brackets, refetch }) => {
  const [selectedSeed, setSelectedSeed] = React.useState<KnockoutSeed>();

  const prevBrackets = usePrevious(brackets);

  React.useEffect(() => {
    if (prevBrackets !== brackets) {
      const newSelectedSeed = brackets
        .reduce<KnockoutSeed[]>((prev, curr) => [...prev, ...curr.seeds], [])
        .find((s) => s.match.id === selectedSeed?.match.id);

      setSelectedSeed(newSelectedSeed);
    }
  }, [prevBrackets, brackets, selectedSeed]);

  const rounds: any[] = brackets.map((round, index, arr) => {
    if (index === 0) {
      const totalRoundOneMatches = arr.length > 1 ? arr[1].seeds.length * 2 : 1;
      const actualRoundOneMatches = round.seeds.length;
      const matches = round.seeds.slice();

      const { seeds: newSeeds } = Array(totalRoundOneMatches)
        .fill({})
        .reduce(
          (prev, curr, currentIndex) => {
            if (prev.numberOfInvisibleMatchesLeft === 0) {
              prev.seeds.push(matches.shift());
              return prev;
            }

            if (currentIndex % 2 === 0) {
              prev.seeds.splice(currentIndex, 0, {} as any);
              prev.numberOfInvisibleMatchesLeft--;

              return prev;
            }

            prev.seeds.push(matches.length ? matches.shift() : {});
            return prev;
          },
          {
            seeds: [],
            numberOfInvisibleMatchesLeft:
              totalRoundOneMatches - actualRoundOneMatches,
          },
        );

      return { ...round, seeds: newSeeds };
    }

    return round;
  });

  const CustomSeed = ({ seed, breakpoint, roundIndex, seedIndex }: any) => {
    // breakpoint passed to Bracket component
    // to check if mobile view is triggered or not

    // mobileBreakpoint is required to be passed down to a seed
    const isInvisible = !seed || !seed.teams;

    const teamOne = !isInvisible ? seed.teams[0] : null;
    const teamOneName = teamOne ? seed.match?.home?.teamName : null;

    const teamTwo = !isInvisible ? seed.teams[1] : null;
    const teamTwoName = teamTwo ? seed.match?.away?.teamName : null;

    return (
      <Seed
        mobileBreakpoint={breakpoint}
        style={{ fontSize: 12, opacity: isInvisible ? 0 : undefined }}
      >
        <SeedItem
          onClick={() => !isInvisible && setSelectedSeed(seed)}
          style={{
            background: 'white',
            cursor: !isInvisible ? 'pointer' : undefined,
          }}
        >
          {!isInvisible ? (
            <div>
              <SeedTeam style={{ color: '#37474f', padding: 8 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {teamOne?.logo ? (
                    <img src={teamOne.logo} alt="" style={{ height: 20 }} />
                  ) : null}
                  <Typography variant="subtitle2" style={{ marginLeft: 8 }}>
                    {teamOneName || 'TBD'}
                  </Typography>
                </div>
                <Typography variant="subtitle2" style={{ padding: '0px 8px' }}>
                  {teamOne?.score ?? 0}
                </Typography>
              </SeedTeam>
              <div
                style={{
                  borderTop: '1px solid rgba(224, 224, 224, 1)',
                  margin: '0 10px',
                }}
              ></div>
              <SeedTeam style={{ color: '#37474f', padding: 8 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {teamTwo?.logo ? (
                    <img src={teamTwo.logo} alt="" style={{ height: 20 }} />
                  ) : null}
                  <Typography variant="subtitle2" style={{ marginLeft: 8 }}>
                    {teamTwoName || 'TBD'}
                  </Typography>
                </div>
                <Typography variant="subtitle2" style={{ padding: '0px 8px' }}>
                  {teamTwo?.score ?? 0}
                </Typography>
              </SeedTeam>
            </div>
          ) : (
            <div>
              <SeedTeam>-----</SeedTeam>
              <SeedTeam>-----</SeedTeam>
            </div>
          )}
        </SeedItem>
        <SeedTime
          mobileBreakpoint={breakpoint}
          style={{ fontSize: 9, marginTop: 8 }}
        >
          {seed.date
            ? format(new Date(seed.date), DateTimeFormat.MEDIUM)
            : 'N/A'}
        </SeedTime>
      </Seed>
    );
  };

  return (
    <React.Fragment>
      <Bracket rounds={rounds} renderSeedComponent={CustomSeed} />

      {selectedSeed && (
        <MatchRoundsDialog
          knockoutSeed={selectedSeed}
          refetch={refetch}
          dialogProps={{
            open: Boolean(selectedSeed),
            onClose: (e) => setSelectedSeed(undefined),
            fullWidth: true,
            maxWidth: 'md',
          }}
        />
      )}
    </React.Fragment>
  );
};
