import { DialogProps, Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ApiClient } from 'ApiClient';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { Club, Team, TeamCategory } from 'models';
import * as React from 'react';

interface Props {
  dialogProps: DialogProps;
  refetch: () => void;
  club: Club;
  team?: Team;
  teamCategories?: TeamCategory[];
}

interface TeamForm {
  name?: string;
  teamCategoryId?: number;
}

export const SaveTeamDialog: React.FC<Props> = ({
  dialogProps,
  club,
  team,
  teamCategories,
  refetch,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const getForm = React.useCallback(function getForm(
    team?: Team,
  ): TeamForm {
    return {
      name: team?.name ?? undefined,
      teamCategoryId: team?.teamCategoryId ?? undefined,
    };
  },
  []);

  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(getForm(team));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(team));
    }
  }, [getForm, team, justOpened]);

  return (
    <FormDialog
      title={`${team ? 'Edit' : 'Add'} Team`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Name"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            value={teamCategories?.find((t) => t.id === form.teamCategoryId) || null}
            options={teamCategories ?? []}
            getOptionLabel={(option: TeamCategory) => option?.name ?? ''}
            onChange={(e: React.ChangeEvent<{}>, nationality: TeamCategory | null) =>
              handleAutocompleteChange(nationality?.id ?? null, 'teamCategoryId')
            }
            renderInput={(params) => (
              <TextField
                {...params}
                name="teamCategoryId"
                variant="standard"
                label="Category"
                required
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleAutocompleteChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const data = { ...form, clubId: club.id };

      const response = await (team
        ? ApiClient.put(`teams/${team.id}`, data)
        : ApiClient.post(`teams`, data));

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refetch();

      snackbar.open('Team has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
