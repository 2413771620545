import { Typography } from '@material-ui/core';
import { DelayedLinearProgress, Tournament } from 'components';
import { useApiClient } from 'hooks';
import { KnockoutBracket } from 'models';
import * as React from 'react';
import { useParams } from 'react-router-dom';

interface Params {
  competitionId: string;
}

export const Knockout: React.FC = () => {
  const { competitionId } = useParams<Params>();

  const [{ data: brackets, refetch, loading, error }] = useApiClient<
    KnockoutBracket[]
  >(`matches/knockout-bracket/${competitionId}`);

  return loading ? (
    <DelayedLinearProgress />
  ) : (
    <React.Fragment>
      {error && (
        <Typography color="error" component="span">
          {error}
        </Typography>
      )}
      <Tournament brackets={brackets ?? []} refetch={refetch} />
    </React.Fragment>
  );
};
