import {
  Box,
  Card,
  CardContent,
  CardActionArea,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import * as React from 'react';
import { MatchTimer } from 'views/Score/Score';
import { Controls } from '../TeamScore';
import { useStyles } from './styles';

interface Props {
  bgColor: string;
  setControls: React.Dispatch<React.SetStateAction<Controls>>;
  selectedControl: number;
  matchTimer: MatchTimer;
}
export const ScoreControls: React.FC<Props> = ({
  bgColor,
  setControls,
  selectedControl,
  matchTimer,
}) => {
  const classes = useStyles();
  const controls = [
    'Yellow Card',
    '2 Minutes',
    'Red Card',
    'T. Penalty',
    'Blue Card',
    'Goal',
    '7 m. Goal',
    '7 m. Miss',
  ];
  const {
    palette: { getContrastText },
  } = useTheme();

  return (
    <React.Fragment>
      <Grid container spacing={1} className={classes.grid}>
        {controls.map((control, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <Card
              className={
                selectedControl === index ? classes.selected : classes.root
              }
            >
              <CardActionArea
                onClick={(ev) => {
                  !matchTimer.isEnded &&
                    ((matchTimer.isPause && (control === '2 Minutes' || control === 'Red Card' || control === 'Blue Card'))
                    || (matchTimer.isPenalties && (control === 'Yellow Card' || control === 'Red Card' || control === '7 m. Goal' || control === '7 m. Miss'))
                    || (!matchTimer.isPenalties && (control === 'Yellow Card' || control === 'T. Penalty' || control === 'Goal' || control === '7 m. Goal' || control === '7 m. Miss'))) &&
                    setControls((curVal) => ({
                      ...curVal,
                      gameControl: selectedControl === index ? -1 : index,
                    }));
                }}
              >
                <CardContent className={classes.cardContent}>
                  <Box>
                    <Typography
                      variant="h6"
                      style={{
                        color:
                          selectedControl === index
                            ? getContrastText(bgColor)
                            : bgColor,
                      }}
                    >
                      {control}
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
};
