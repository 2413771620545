import { GameControls } from 'enums/GameControls';
import { Snackbar } from 'components';
import { ApiClient } from 'ApiClient';
import { MatchLineup } from 'models/MatchLineup';
import { MatchUpdate } from 'models/MatchUpdate';

export interface MatchUpdateForm {
  id?: number;
  teamId?: number;
  matchLineupId?: number;
  matchId: number;
  matchUpdateTypeId: number;
  updateTime: number;
}

export async function matchUpdateCall(
  matchLineup: MatchLineup,
  teamMatchUpdates: MatchUpdate[],
  form: MatchUpdateForm,
  snackBar: Snackbar,
  action?: string,
): Promise<boolean> {
  const {
    warning,
    redCard,
    redCardTime,
    blueCard,
    min2,
    teamPlayerWarnings,
    teamOfficialWarnings,
  } = getMatchLineupData(matchLineup.matchUpdates, teamMatchUpdates);

  let isUpdateRequired: boolean = false;
  switch (+form.matchUpdateTypeId) {
    case GameControls.WARNING:
      min2 >= 1
        ? snackBar.error('Warning - Player has a 2 min suspension')
        : redCard
        ? snackBar.error('Warning - Player has a Red card')
        : warning
        ? snackBar.error('Warning - already issued to player')
        : matchLineup.playerId && teamPlayerWarnings > 2 && action !== 'EDIT'
        ? snackBar.error('Warning - Maximum of 3 players')
        : matchLineup.clubOfficialId &&
          teamOfficialWarnings > 0 &&
          action !== 'EDIT'
        ? snackBar.error('Warning - Maximum of 1 official')
        : (isUpdateRequired = true);
      break;

    case GameControls.RED_CARD:
      redCard
        ? snackBar.error('Red Card - already issued to player')
        : (isUpdateRequired = true);
      break;

    case GameControls.T_PENALTY:
      isUpdateRequired = true;
      break;

    case GameControls.BLUE_CARD:
      blueCard
        ? snackBar.error('Blue Card - already issued to player')
        : (isUpdateRequired = true);
      break;

    case GameControls.MINUTES_2:
      matchLineup.clubOfficialId && min2 >= 1
        ? snackBar.error('2 Minutes - already issued to the club official.')
        : min2 >= 3
        ? snackBar.error('2 Minutes - already issued to player 3 times.')
        : (isUpdateRequired = true);
      break;

    case GameControls.GOAL:
    case GameControls.M7_GOAL:
      if (matchLineup.clubOfficialId) {
        snackBar.error('Wrong action.');
      } else if (redCard) {
        if (redCardTime && form.updateTime > redCardTime) {
          snackBar.error(
            'Wrong action, Cannot assign Goal as Red Card - issued to player',
          );
        } else {
          isUpdateRequired = true;
        }
      } else {
        isUpdateRequired = true;
      }
      break;
    case GameControls.M7_MISS:
      matchLineup.clubOfficialId
        ? snackBar.error('Wrong action.')
        : (isUpdateRequired = true);
      break;
  }

  if (isUpdateRequired) {
    let response;
    switch (action) {
      case 'EDIT':
        response = await ApiClient.post(`scores/edit-action/` + form.id, form);
        break;
      default:
        response = await ApiClient.post(`scores/add-action`, form);
        break;
    }
    if (response) {
      return true;
    }
  }
  return false;
}

export function formatUpdateTime(duration: number) {
  const minutes = Math.floor(duration / 60);
  const seconds = Math.floor(duration - minutes * 60);
  const display =
    (minutes > 9 ? minutes : '0' + minutes) +
    ':' +
    (seconds > 9 ? seconds : '0' + seconds);
  return { minutes, seconds, display };
}

export function getMatchLineupData(
  matchUpdates: MatchUpdate[],
  teamMatchUpdates: MatchUpdate[],
) {
  const warning =
    matchUpdates.filter((u) => u.matchUpdateTypeId === GameControls.WARNING)
      .length > 0;

  const redCardMatchUpdate = matchUpdates.filter(
    (u) =>
      u.matchUpdateTypeId === GameControls.RED_CARD ||
      u.matchUpdateTypeId === GameControls.RED_CARD_2MIN,
  );

  const redCard = redCardMatchUpdate.length > 0;
  const redCardTime = redCard ? redCardMatchUpdate[0].updateTime : undefined;

  const blueCard =
    matchUpdates.filter((u) => u.matchUpdateTypeId === GameControls.BLUE_CARD)
      .length > 0;

  const min2 = matchUpdates.filter(
    (u) => u.matchUpdateTypeId === GameControls.MINUTES_2,
  ).length;

  const teamPlayerWarnings = teamMatchUpdates.filter(
    (u) =>
      u.matchUpdateTypeId === GameControls.WARNING && u.matchLineup?.playerId,
  ).length;

  const teamOfficialWarnings = teamMatchUpdates.filter(
    (u) =>
      u.matchUpdateTypeId === GameControls.WARNING &&
      u.matchLineup?.clubOfficialId,
  ).length;

  return {
    warning,
    redCard,
    redCardTime,
    blueCard,
    min2,
    teamPlayerWarnings,
    teamOfficialWarnings,
  };
}
