import { DialogProps, Grid, TextField } from '@material-ui/core';
import { ApiClient } from 'ApiClient';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { SeasonCategory, Season } from 'models';
import * as React from 'react';

interface Props {
  dialogProps: DialogProps;
  refetch: () => void;
  season: Season;
  seasonCategory?: SeasonCategory;
}

interface SeasonCategoryForm {
  name?: string;
}

export const SaveSeasonCategoryDialog: React.FC<Props> = ({
  dialogProps,
  season,
  seasonCategory,
  refetch,
}) => {
  const snackbar = React.useContext(SnackbarContext);

  const getForm = React.useCallback(function getForm(
    seasonCategory?: SeasonCategory,
  ): SeasonCategoryForm {
    return {
      name: seasonCategory?.name ?? undefined,
    };
  },
  []);

  const [loading, setLoading] = React.useState(false);
  const [form, setForm] = React.useState(getForm(seasonCategory));

  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm(seasonCategory));
    }
  }, [getForm, seasonCategory, justOpened]);

  return (
    <FormDialog
      title={`${seasonCategory ? 'Edit' : 'Add'} Category`}
      submitText="Save"
      onSubmit={submit}
      loading={loading}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={12}>
          <TextField
            label="Name"
            type="text"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="name"
            value={form.name || ''}
            required
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);

      const data = { ...form, seasonId: season.id };

      const response = await (seasonCategory
        ? ApiClient.put(`season-categories/${seasonCategory.id}`, data)
        : ApiClient.post(`season-categories`, data));

      if (!response) {
        return;
      }

      if (dialogProps.onClose) {
        dialogProps.onClose(e);
      }

      refetch();

      snackbar.open('Category has been saved successfully.');
    } catch (error) {
      snackbar.error(error);
    } finally {
      setLoading(false);
    }
  }
};
