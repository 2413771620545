import {
  Box,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import * as React from 'react';
import { PlayerScore } from '../PlayerScore';
import { ScoreControls } from '../ScoreControls';
import { SketchPicker } from 'react-color';
import { Palette } from '@material-ui/icons';
import { Color, MatchTimer } from 'views/Score/Score';
import { GameControls } from 'enums/GameControls';
import Cookies from 'js-cookie';
import { Match } from 'models';

interface Props {
  rightAlignment?: boolean;
  match: Match;
  setColor: React.Dispatch<React.SetStateAction<Color>>;
  color: Color;
  refetch: () => void;
  matchTimer: MatchTimer;
}

export interface Controls {
  gameControl: GameControls;
}

const useStyles = makeStyles((theme) => ({
  grid: {
    paddingRight: theme.spacing(1.2),
    paddingLeft: theme.spacing(1.2),
    maxHeight: 550,
    overflowY: 'scroll',
  },
  colorPalette: {
    position: 'absolute',
    zIndex: 4,
  },
}));
export const TeamScore: React.FC<Props> = ({
  rightAlignment = false,
  match,
  setColor,
  color,
  refetch,
  matchTimer,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const {
    palette: { getContrastText },
  } = useTheme();

  const [control, setGameControl] = React.useState<Controls>({
    gameControl: -1,
  });
  
  const team = rightAlignment ? match.away.team : match.home.team;
  const teamColor = rightAlignment ? color.away : color.home;
  const teamName = rightAlignment ? match.away.teamName : match.home.teamName;

  React.useEffect(() => {
    if (!(matchTimer.isActive && !matchTimer.isPause)) control.gameControl = -1;
  }, [matchTimer, control.gameControl]);

  return (
    <React.Fragment>
      <Box style={{ backgroundColor: teamColor }}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton
            aria-label="colorPalette"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <Palette style={{ color: getContrastText(teamColor) }} />
          </IconButton>
          <Typography
            style={{ color: getContrastText(teamColor) }}
            variant="h3"
          >
            {teamName}
          </Typography>
          <Box>
            <Box className={classes.colorPalette}>
              <Box>
                {open ? (
                  <SketchPicker
                    color={teamColor}
                    onChange={(col) => {
                      handleColorUpdate(col.hex);
                    }}
                  />
                ) : (
                  ''
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider />
        <ScoreControls
          setControls={setGameControl}
          bgColor={teamColor}
          selectedControl={control.gameControl}
          matchTimer={matchTimer}
        />
      </Box>
      <Divider />
      <Box pt={2}>
        <Grid container spacing={1} className={classes.grid}>
          {team.matchLineups.filter((t) => t.playerId).map((matchLineup, index) => (
            <PlayerScore
              setControls={setGameControl}
              gameControl={control.gameControl}
              bgColor={teamColor}
              matchLineup={matchLineup}
              teamMatchUpdates={match.matchUpdates.filter((x) => x.teamId === matchLineup.teamId)}
              key={matchLineup.gameNumber}
              refetch={refetch}
              matchTimer={matchTimer}
            />
          ))}
        </Grid>
        <Grid container spacing={1} className={classes.grid}>
          {team.matchLineups.filter((t) => t.clubOfficialId).map((matchLineup, index) => (
            <PlayerScore
              setControls={setGameControl}
              gameControl={control.gameControl}
              bgColor={teamColor}
              matchLineup={matchLineup}
              teamMatchUpdates={match.matchUpdates.filter((x) => x.teamId === matchLineup.teamId)}
              key={matchLineup.gameNumber}
              refetch={refetch}
              matchTimer={matchTimer}
            />
          ))}
        </Grid>
      </Box>
    </React.Fragment>
  );

  function handleColorUpdate(col: string) {
    let date = new Date();
    date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
    Cookies.set(rightAlignment ? 'rightTeamColor' : 'leftTeamColor', col, {
      expires: date,
      path: '/',
    });
    rightAlignment
      ? setColor((curState) => ({
          ...curState,
          away: col,
        }))
      : setColor((curState) => ({
          ...curState,
          home: col,
        }));
  }
};
