import { Button, Paper, Toolbar, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { ApiClient } from 'ApiClient';
import {
  ConfirmDialog,
  ResponsiveIconButton,
  RouterLink,
  SnackbarContext,
  SortableTable,
  TableControls,
} from 'components';
import { SortableTableHeader } from 'components/SortableTable/components';
import { useApiClient } from 'hooks';
import { Club } from 'models';
import * as React from 'react';
import { SaveClubDialog } from './components';
import { Config } from 'config';

export const ClubsContainer: React.FC = () => {
  const snackbar = React.useContext(SnackbarContext);

  const [{ data: clubs, refetch }] = useApiClient<Club[]>('clubs');

  const [modalOpen, setModalOpen] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [selectedClub, setSelectedClub] = React.useState<Club>();

  const columns: SortableTableHeader[] = [
    { key: 'name', label: 'Name' },
    { key: 'address', label: 'Address' },
    { key: 'phone', label: 'Phone' },
    { key: 'primaryEmail', label: 'Primary Email' },
    { key: 'secondaryEmail', label: 'Secondary Email' },
    { key: 'image', label: 'Logo' },
    { key: 'officials', label: 'Club Officials' },
    { key: 'controls', sortable: false },
  ];

  const rows =
    clubs?.map((club) => ({
      key: club.id.toString(),
      cells: [
        {
          key: 'name',
          display: (
            <Button
              component={RouterLink}
              to={`/clubs/${club.id.toString()}/teams`}
              variant="text"
              size="small"
              color="secondary"
            >
              {club.name}
            </Button>
          ),
        },
        { key: 'address', display: club.address ?? '' },
        { key: 'phone', display: club.phone ?? '' },
        { key: 'primaryEmail', display: club.primaryEmail ?? '' },
        { key: 'secondaryEmail', display: club.secondaryEmail ?? '' },
        {
          key: 'image',
          display: club.imageUrl ? (
            <img
              src={`${Config.IMAGE_CDN_URL}${club.imageUrl}`}
              alt=""
              style={{ height: 60 }}
            />
          ) : (
            'N/A'
          ),
        },
        {
          key: 'officials',
          display: (
            <Button
              component={RouterLink}
              to={`/clubs/${club.id.toString()}/club-officials`}
              variant="text"
              size="small"
              color="secondary"
            >
              View Club Officials
            </Button>
          ),
        },
        {
          key: 'controls',
          display: (
            <TableControls
              onEdit={(e) => {
                setModalOpen(true);
                setSelectedClub(club);
              }}
              onDelete={(e) => {
                setConfirmOpen(true);
                setSelectedClub(club);
              }}
            />
          ),
        },
      ],
    })) ?? [];

  return (
    <Paper>
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Typography variant="h6">Clubs</Typography>
        <ResponsiveIconButton
          onClick={(e) => {
            setSelectedClub(undefined);
            setModalOpen(true);
          }}
          color="primary"
          icon={AddIcon}
        >
          Add Club
        </ResponsiveIconButton>
      </Toolbar>

      <SortableTable
        columns={columns}
        rows={rows}
        emptyTableText="No clubs available yet."
        emptyTableCreateAction={(e) => setModalOpen(true)}
        disablePagination
      />

      <SaveClubDialog
        club={selectedClub}
        refetch={refetch}
        dialogProps={{
          open: modalOpen,
          onClose: (e) => setModalOpen(false),
          fullWidth: true,
          maxWidth: 'sm',
        }}
      />

      <ConfirmDialog
        title="Confirm"
        content="Are you sure you would like to delete this club?"
        onConfirm={deleteClub}
        dialogProps={{
          open: confirmOpen,
          onClose: (e) => {
            setConfirmOpen(false);
            setSelectedClub(undefined);
          },
        }}
      />
    </Paper>
  );

  async function deleteClub() {
    try {
      if (!selectedClub) {
        return;
      }

      const response = await ApiClient.delete(`clubs/${selectedClub.id}`);

      if (!response) {
        return;
      }

      refetch();

      snackbar.open('Club has been deleted successfully');
    } catch (error) {
      snackbar.error(error);
    }
  }
};
