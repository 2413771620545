import {
  Box,
  DialogProps,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { FormDialog, SnackbarContext } from 'components';
import { usePrevious } from 'hooks';
import { Match, Team } from 'models';
import { MatchLineup } from 'models/MatchLineup';
import { CompetitionTypes } from 'enums/CompetitionTypes';
import * as React from 'react';
import {
  formatUpdateTime,
  matchUpdateCall,
  MatchUpdateForm,
} from 'views/Score/lib/ScoreUtility';
import { Color } from 'views/Score/Score';
import { ApiClient } from 'ApiClient';

interface Props {
  dialogProps: DialogProps;
  id: number;
  match: Match;
  color: Color;
  refetch: () => void;
}

interface UpdatesForm {
  minutes: number;
  seconds: number;
  teamId: number;
  matchLineupId: number;
}

export const UpdatesDialog: React.FC<Props> = ({
  dialogProps,
  id,
  match,
  color,
  refetch,
}) => {
  const teams = [match.away.team, match.home.team];
  const matchUpdate = match.matchUpdates.find((u) => u.id === id);
  const prevOpen = usePrevious(dialogProps.open);
  const justOpened = dialogProps.open && !prevOpen;
  const snackbar = React.useContext(SnackbarContext);

  const [matchLineups, setMatchLineups] = React.useState<MatchLineup[]>(
    matchUpdate?.teamId === match.away.teamId
      ? match.away.team.matchLineups
      : match.home.team.matchLineups,
  );

  React.useEffect(() => {
    setMatchLineups(
      matchUpdate?.teamId === match.away.teamId
        ? match.away.team.matchLineups
        : match.home.team.matchLineups,
    );
  }, [
    matchUpdate,
    match.away.team.matchLineups,
    match.away.teamId,
    match.home.team.matchLineups,
  ]);

  const getForm = React.useCallback(
    function getForm(): UpdatesForm {
      return {
        teamId: matchUpdate?.teamId ?? -1,
        matchLineupId: matchUpdate?.matchLineupId ?? -1,
        minutes: formatUpdateTime(matchUpdate?.updateTime ?? 0).minutes,
        seconds: formatUpdateTime(matchUpdate?.updateTime ?? 0).seconds,
      };
    },
    [matchUpdate],
  );
  const [form, setForm] = React.useState(getForm());

  React.useEffect(() => {
    if (justOpened) {
      setForm(getForm());
    }
  }, [getForm, justOpened]);

  return (
    <FormDialog
      title="Edit Update"
      submitText="Save"
      onSubmit={submit}
      dialogProps={dialogProps}
    >
      <Grid container>
        <Grid item xs={3} md={6} lg={12}>
          <Typography>
            {id === -1 ? '' : matchUpdate?.matchUpdateType.name}
          </Typography>
        </Grid>
        <Grid item xs={3} md={6}>
          <TextField
            label="Minutes"
            type="number"
            fullWidth
            onChange={(e) => handleChange(e)}
            name="minutes"
            value={form.minutes}
            required
            InputProps={{
              inputProps: {
                max: match.competition.competitionTypeId === CompetitionTypes.KNOCKOUT ? 71 : 59,
                min: 0,
                step: 1,
              },
            }}
          />
        </Grid>
        <Grid item xs={3} md={6}>
          <TextField
            label="Seconds"
            type="number"
            onChange={(e) => handleChange(e)}
            fullWidth
            name="seconds"
            value={form.seconds}
            required
            InputProps={{
              inputProps: { step: 1, min: 0, max: 59 },
            }}
          />
        </Grid>
        <Grid item xs={3} md={6}>
          <Autocomplete
            value={teams?.find((t) => t.id === form.teamId)}
            options={teams}
            getOptionLabel={(option) => option.name}
            onChange={(e: React.ChangeEvent<{}>, team: Team | null) =>
              handleTeamChange(team?.id ?? null, 'teamId')
            }
            renderOption={(option) => (
              <React.Fragment>
                <Box p={1}>
                  <Box
                    style={{
                      backgroundColor:
                        option.id === match.away.teamId
                          ? color.away
                          : color.home,
                    }}
                    p={1}
                  ></Box>
                </Box>
                {option.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name="teamId"
                variant="standard"
                label="Team"
                required
              />
            )}
          />
        </Grid>
        <Grid item xs={3} md={6}>
          <Autocomplete
            options={matchLineups}
            getOptionLabel={(option) =>
              option.clubOfficialId
                ? option.clubOfficial.name
                : option.player.name
            }
            value={matchLineups.find((m) => m.id === form.matchLineupId)}
            onChange={(
              e: React.ChangeEvent<{}>,
              matchLineup: MatchLineup | null,
            ) => handlePlayerChange(matchLineup?.id ?? null, 'matchLineupId')}
            renderOption={(option) => (
              <React.Fragment>
                <Box p={1}>
                  <Box
                    style={{
                      backgroundColor:
                        matchUpdate?.teamId === match.away.teamId
                          ? color.away
                          : color.home,
                    }}
                    p={1}
                  >
                    {option.gameNumber}
                  </Box>
                </Box>
                {option.clubOfficialId
                  ? option.clubOfficial.name
                  : option.player.name}
              </React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name="playerId"
                variant="standard"
                label="Player"
                required
              />
            )}
          />
        </Grid>
      </Grid>
    </FormDialog>
  );

  function handleChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    handleUpdatePlayerAction();
    if (dialogProps.onClose) {
      dialogProps.onClose(e);
    }
  }

  function handlePlayerChange(value: any, name: string) {
    setForm((f) => ({ ...f, [name]: value }));
  }

  function handleTeamChange(value: any, name: string) {
    setMatchLineups(
      value === match.away.teamId
        ? match.away.team.matchLineups
        : match.home.team.matchLineups,
    );
    setForm((f) => ({ ...f, [name]: value }));
  }

  async function handleUpdatePlayerAction() {
    const data: MatchUpdateForm = {
      teamId: form.teamId,
      matchLineupId: form.matchLineupId,
      matchId: matchUpdate?.matchId ?? -1,
      matchUpdateTypeId: matchUpdate?.matchUpdateTypeId ?? -1,
      updateTime: Number(form.minutes * 60) + Number(form.seconds),
      id: matchUpdate?.id ?? -1,
    };

    if (
      form.teamId === matchUpdate?.teamId &&
      form.matchLineupId === matchUpdate.matchLineupId
    ) {
      //not editing team or player
      const response = await ApiClient.post(
        `scores/edit-action/` + data.id,
        data,
      );

      if (response) {
        refetch();
      }
      return;
    }
    const matchLineup = matchLineups.find((m) => m.id === form.matchLineupId);
    const teamMatchUpdates = match.matchUpdates.filter(
      (x) => x.teamId === matchLineup?.teamId,
    );
    const result =
      matchLineup &&
      matchUpdateCall(matchLineup, teamMatchUpdates, data, snackbar, 'EDIT');
    if (result) {
      refetch();
    }
  }
};
