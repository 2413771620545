const env = process.env;

export const Config = {
  DEBUG: env.NODE_ENV ? env.NODE_ENV === 'development' : true,
  API_BASE_URL: env.REACT_APP_API_BASE_URL || 'http://localhost:8765/',
  GOOGLE_API_KEY:
    env.REACT_APP_GOOGLE_API_KEY || 'AIzaSyCgp-HEhxa5Uf1zgjTKdE2bgx1vYNBoVqM',
  IMAGE_CDN_URL:
    env.REACT_APP_IMAGE_CDN_URL ||
    'https://handball-live-score-staging-public.s3.eu-central-1.amazonaws.com/images/',
};
